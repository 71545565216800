//@ts-nocheck
import {
  Box,
  Button,
  Drawer,
  Grid,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { getAllResources } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import EmptyScreen from "../../../components/EmptyScreen";
import Loader from "../../../components/Loader";
import ResourcesCard from "../../../components/ResourcesCard";
import ArticlesForm from "./ArticlesForm";

const Articles = () => {
  const [open, setOpen] = useState(false);
  const [articles, setArticles] = useState([]);
  const [formData, setFormData] = useState([]);
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const { data, isLoading } = useQuery(
    [
      "articles",
      {
        type: "ARTICLE",
        limit: limit,
        offset: limit * page,
      },
    ],
    getAllResources,

    {
      onSuccess: (data) => {
        setArticles(data?.resources);
      },
    }
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {" "}
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Box sx={{ display: "flex" }} gap={"26px"} alignItems="center">
          <Typography variant="h3">Articles</Typography>
          <Button
            onClick={() => {
              setFormData(null);
              toggleDrawer(true);
            }}
            variant="contained"
          >
            + Add Article
          </Button>
        </Box>
      </Box>
      {data?.data?.count === 0 ? (
        <EmptyScreen type={"articles"} />
      ) : (
        <>
          <Grid container spacing={7}>
            {data?.data?.resources?.map((item) => {
              return (
                <Grid item xs={4}>
                  <ResourcesCard
                    data={item}
                    image={item?.link}
                    thumbnail={item?.thumbnail}
                    date={item?.createdAt}
                    title={item?.title}
                    desc={item?.description}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TablePagination
              count={data?.data.count}
              page={page}
              onPageChange={handlePageChange}
              rowsPerPage={limit}
              onRowsPerPageChange={handleRowsPerPageChange}
            />
          </Box>
        </>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
            }}
          >
            {formData ? "Edit Article" : "Add Article"}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: "550px" }}>
            <ArticlesForm data={formData} toggleDrawer={toggleDrawer} />
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
};

export default Articles;
