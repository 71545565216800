import { Box, Button, Grid, useTheme } from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPayoutStatistics } from "../../../../../api/services/api";
import AnalyticsCard from "../../../../../components/AnalyticsCard/AnalyticsCard";
import { PayoutsList } from "./PayoutsList";
import { SessionPayments } from "./SessionPayments";

function TherapistPayouts() {
  const clinicianId = useParams();
  const theme = useTheme();
  const [showPayments, setshowPayments] = useState(true);

  const { data, isLoading } = useQuery(
    ["payoutsStats", clinicianId?.id],
    getPayoutStatistics,
    {
      enabled: Boolean(clinicianId?.id),
    }
  );

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <AnalyticsCard
                title="Total Session Amount"
                number={data?.data?.totalPayment}
                AvatarColor={theme.colors.warning.main}
                bg={theme.colors.warning.lighter}
                ChartColor={theme.colors.warning.main}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                title="Total Amount Paid"
                number={data?.data?.clinicianPayout}
                AvatarColor={theme.colors.success.main}
                bg={theme.colors.success.lighter}
                ChartColor={theme.colors.success.main}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                title="Pending Payouts"
                number={data?.data?.pendingPayout}
                AvatarColor={theme.colors.primary.main}
                bg={theme.colors.primary.lighter}
                ChartColor={theme.colors.primary.main}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box mt="40px" display="flex" gap={2}>
          <Button
            variant={showPayments ? "contained" : "outlined"}
            onClick={() => setshowPayments(true)}
          >
            Session Payments
          </Button>
          <Button
            variant={showPayments ? "outlined" : "contained"}
            onClick={() => setshowPayments(false)}
          >
            Session Payouts
          </Button>
        </Box>
        <Grid item xs={12}>
          {showPayments ? (
            <SessionPayments id={clinicianId?.id} />
          ) : (
            <PayoutsList id={clinicianId?.id} />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default TherapistPayouts;
