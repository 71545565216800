import { Box, Card, Divider, Switch, Typography } from '@mui/material'
import React from 'react'

export const PermissionCard = ({ title, onChange, value }: any) => {
    return (
        <Box>
            <Card>
                <Box display="flex" gap={2} px={2} py={1} alignItems="center" >
                    <Typography>
                        {title}
                    </Typography>
                    <Switch checked={value} onChange={onChange} />
                </Box>
                <Divider />
                <Box display="flex" py={3} px={2} gap={5} >
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography>
                            Create
                        </Typography>
                        <Switch checked={true} />
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography>
                            Edit
                        </Typography>
                        <Switch checked={true} />
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography>
                            View
                        </Typography>
                        <Switch checked={true} />
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography>
                            Delete
                        </Typography>
                        <Switch checked={true} />
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                        <Typography>
                            Export
                        </Typography>
                        <Switch checked={true} />
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}
