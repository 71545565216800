import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
  Box,
  Button,
  Drawer,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import {
  deletePayout,
  getAllPayouts,
  payoutSats,
} from "../../../api/services/api";
import AnalyticsCard from "../../../components/AnalyticsCard/AnalyticsCard";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import EmptyScreen from "../../../components/EmptyScreen";
import { DeleteButton, EditButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import { PayoutForm } from "./PayoutForm";
import { SessionPayments } from "./SessionPayments";

export const Payouts = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const [showPayments, setshowPayments] = useState(true);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [formData, setFormData] = useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [excelArr, setExcelArr] = useState<any>();
  const date = new Date();
  const todayDate = moment(date).format("DD-MM-YYYY");

  const openAnchor = Boolean(anchorEl);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const handleEditClick = (row: any) => {
    setFormData(row);
    toggleDrawer(true);
  };

  const handleDeleteClick = (row: any) => {
    mutate(row?.id);
  };

  const handleAddPayout = () => {
    setFormData(null);
    toggleDrawer(true);
  };

  const { data: payoutsData, isLoading } = useQuery(
    [
      "payouts",
      {
        search: search,
        limit: limit,
        offset: limit * page,
        fromDate: startDate,
        toDate: endDate,
      },
    ],
    getAllPayouts
  );

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  let i = 0;
  const excelData = payoutsData?.data?.payouts?.map((item: any) => ({
    "S.No": ++i,
    "Payment Reference ID": item?.paymentReferenceId,
    "Payment Date": moment(item?.paymentDate).format("DD-MM-YYYY"),
    Payment: `${item?.amount} SAR`,
    "Payment Mode": item?.paymentMode,
  }));

  useEffect(() => {
    async function generateFile(rows: any, fileName: string) {
      const worksheet = XLSX.utils?.json_to_sheet(rows);
      const workbook = XLSX.utils?.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
      return await XLSX?.write(workbook, { type: "buffer" });
    }
    payoutsData?.data?.payouts &&
      generateFile(excelData, "Report.xlsx").then((res) => {
        setExcelArr(res);
      });
  }, [payoutsData?.data?.payouts]);

  const handleExcelImport = () => {
    const arr = new Uint8Array(excelArr);
    const blob = new Blob([arr], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const file = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = file;
    link.download = `Report_${todayDate}.xlsx`;
    link.click();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data: payoutData } = useQuery("payoutStats", payoutSats);

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { mutate } = useMutation(deletePayout, {
    onSuccess: () => {
      toast.success("Payout deleted successfully");
      queryClient.invalidateQueries("payouts");
      queryClient.invalidateQueries("payoutStats");
    },
  });

  const columns = [
    {
      title: "Therapist Name",
      dataIndex: "clinician.user.fullName",
      key: "clinician.user.fullName",
    },
    {
      title: "Payment Reference ID",
      dataIndex: "paymentReferenceId",
      key: "paymentReferenceId",
    },

    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (colData: any) => moment(colData).format("DD-MMM-YYYY"),
    },
    {
      title: "Payment",
      dataIndex: "amount",
      key: "amount",
      render: (colData: any) => `${colData} SAR`,
    },

    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <Box display="flex" gap="20px">
          <EditButton onClick={() => handleEditClick(row)} />
          <DeleteButton onClick={() => handleDeleteClick(row)} />
        </Box>
      ),
    },
  ];

  return (
    <>
      <Grid mt={1} container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <AnalyticsCard
                icon={<WatchLaterIcon />}
                number={payoutData?.data?.totalPayout || 0}
                title="Total Payments"
                AvatarColor={theme.colors.warning.main}
                bg={theme.colors.warning.lighter}
                ChartColor={theme.colors.warning.main}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                icon={<WatchLaterIcon />}
                number={payoutData?.data?.clinicianPayout || 0}
                title="Therapist Payments"
                AvatarColor={theme.colors.success.main}
                bg={theme.colors.success.lighter}
                ChartColor={theme.colors.success.main}
              />
            </Grid>
            <Grid item xs={4}>
              <AnalyticsCard
                icon={<WatchLaterIcon />}
                number={payoutData?.data?.alsanCommision || 0}
                title="ALSAN Commission"
                AvatarColor={theme.colors.primary.main}
                bg={theme.colors.primary.lighter}
                ChartColor={theme.colors.primary.main}
              />
            </Grid>
          </Grid>
        </Grid>
        <Box mt="50px" display="flex" gap={2}>
          <Button
            variant={showPayments ? "contained" : "outlined"}
            onClick={() => setshowPayments(true)}
          >
            Session Payments
          </Button>
          <Button
            variant={showPayments ? "outlined" : "contained"}
            onClick={() => setshowPayments(false)}
          >
            Session Payouts
          </Button>
        </Box>
        <Grid item xs={12}>
          {showPayments ? (
            <SessionPayments />
          ) : (
            <>
              <Box mt={2} display="flex" justifyContent="space-between">
                <Box display="flex" gap={2}>
                  <TextField
                    label="Search by Therapist Name"
                    sx={{ width: "450px", padding: "0px" }}
                    InputProps={{
                      endAdornment: (
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      ),
                    }}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<FilterListOutlinedIcon />}
                    onClick={handleClick}
                  >
                    <Typography>Filter</Typography>
                  </Button>
                  <Button
                    onClick={handleClearFilter}
                    sx={{
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      ml: -2,
                      "&:hover": { backgroundColor: "transparent" },
                    }}
                    disableRipple
                    disableElevation
                    disableFocusRipple
                    disableTouchRipple
                  >
                    Clear Filter
                  </Button>
                  <Menu
                    open={openAnchor}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Start Date"
                          inputFormat="DD/MM/YYYY"
                          value={startDate}
                          onChange={(newValue: any) => {
                            setStartDate(
                              moment(new Date(newValue)).format("YYYY-MM-DD")
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ width: "230px", mr: 1 }}
                              {...params}
                            />
                          )}
                        />
                        <DatePicker
                          label="End Date"
                          inputFormat="DD/MM/YYYY"
                          value={endDate}
                          onChange={(newValue: any) => {
                            setEndDate(
                              moment(new Date(newValue)).format("YYYY-MM-DD")
                            );
                          }}
                          renderInput={(params) => (
                            <TextField sx={{ width: "230px" }} {...params} />
                          )}
                        />
                      </LocalizationProvider>
                    </MenuItem>
                  </Menu>
                </Box>
                <Box>
                  <Button
                    sx={{ mr: 2 }}
                    onClick={handleAddPayout}
                    variant="contained"
                  >
                    Add Payout
                  </Button>
                  <Button
                    onClick={handleExcelImport}
                    variant="contained"
                    disabled={payoutData?.data?.count === 0 ? true : false}
                  >
                    Export Payouts
                  </Button>
                </Box>
              </Box>
              {payoutsData?.data?.count == 0 ? (
                <EmptyScreen type="Payouts" />
              ) : (
                <>
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Box mt={2}>
                      <Table
                        headAlign={"center"}
                        tableAlign={"center"}
                        key={"payouts"}
                        dataSource={payoutsData?.data?.payouts}
                        rowKey={"id"}
                        columns={columns}
                        loading={isLoading}
                      />
                      <Box
                        sx={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <TablePagination
                          count={payoutsData?.data.count}
                          page={page}
                          onPageChange={handlePageChange}
                          rowsPerPage={limit}
                          rowsPerPageOptions={[5, 10, 20, 50]}
                          onRowsPerPageChange={handleRowsPerPageChange}
                        />
                      </Box>
                    </Box>
                  )}
                </>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography variant="h4">
            {formData ? "Edit Payout" : "Add Payout"}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <PayoutForm
            toggleDrawer={(e: any) => toggleDrawer(e)}
            formData={formData}
          />
        </StyledDrawerContent>
      </Drawer>
    </>
  );
};
