import { AppBar } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { NavLink, Outlet, useParams } from "react-router-dom";

function TherapistDetails() {
  const id = useParams();

  return (
    <div>
      <Box>
        <AppBar
          style={{
            // position: 'absolute',
            top: 80,
            left: "270px",
            backgroundColor: "#F4F4F4",
            boxShadow: "none",
          }}
        >
          <nav
            style={{
              display: "flex",
              paddingLeft: "38px",
            }}
          >
            <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/dashboard`}
              end
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Dashboard
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink>
            <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/sessions`}
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Sessions
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink>
            <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/payouts`}
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Payouts
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink>
            <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/reports`}
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Reports
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink>
            {/* <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/reviews`}
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Reviews
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink> */}
            <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/patients`}
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Clients
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink>
            <NavLink
              className={"resourcesNavLink"}
              to={`/therapists/details/${id?.id}/slot-management`}
            >
              <Typography
                className="resourcesNavLinkText"
                sx={{ fontSize: "15px" }}
              >
                Slot Management
              </Typography>
              <Box id="borderBottom"></Box>
            </NavLink>
          </nav>
        </AppBar>
      </Box>

      <Box sx={{ marginTop: "50px" }}>
        <Outlet />
      </Box>
    </div>
  );
}

export default TherapistDetails;
