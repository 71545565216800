//@ts-nocheck
import { Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../../api/http";
import ImageUpload from "../../../components/ImageUpload";

const ArticlesForm = ({ data, toggleDrawer }: any) => {
  const [edit, setEdit] = useState(false);
  const [id, setId] = useState();
  const [picDetails, setPicDetails] = useState(null);
  const [editImage, setEditImage] = useState(data?.thumbnail);

  const isValidUrl = (urlString) => {
    try {
      return Boolean(new URL(urlString));
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    data && setEdit(true);
    data && setId(data?.id);
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      title: data?.title,
      description: data?.description,
      link: data?.link,
    },
  });

  const queryClient = useQueryClient();

  console.log(data);

  const onSubmit = async (data: any) => {
    if (edit) {
      console.log(id);
      const formData = {
        ...data,
        thumbnail: picDetails?.Location ? picDetails?.Location : editImage,
        type: "ARTICLE",
      };
      console.log(isValidUrl(formData?.link));

      if (!isValidUrl(formData?.link)) {
        return toast.error("The article link is not a valid URL");
      }
      http
        .patch(`/resources/${id}`, formData)
        .then((res) => {
          toast.success("The article details have been updated");
          queryClient.invalidateQueries("articles");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      console.log(data);
      const formData = {
        ...data,
        thumbnail: picDetails?.Location ? picDetails?.Location : editImage,
        type: "ARTICLE",
      };
      if (!isValidUrl(formData?.link)) {
        return toast.error("The article link is not a valid URL");
      }
      http
        .post(`/resources`, formData)
        .then((res) => {
          toast.success("The article has been created");
          queryClient.invalidateQueries("articles");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
    console.log(data);
  };
  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type={"text"}
            {...register("title", {
              required: true,
            })}
            label={"Headline"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.title?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          <TextField
            type={"text"}
            multiline
            rows={2}
            {...register("description", {
              required: true,
            })}
            label={"Description"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.description?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          <TextField
            type={"text"}
            {...register("link", {
              required: true,
            })}
            label={"Article Link"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.link?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}

          <ImageUpload
            picDetails={picDetails}
            setPicDetails={setPicDetails}
            editImage={editImage}
            setEditImage={setEditImage}
            title={"thumbnail"}
          />
          <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default ArticlesForm;
