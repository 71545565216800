import SummarizeIcon from "@mui/icons-material/Summarize";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getPatientDasboardData } from "../../../../api/services/api";
import AnalyticsCard from "../../../../components/AnalyticsCard/AnalyticsCard";

function SessionAnalytics() {
  const patientId = useParams();
  const theme = useTheme();

  const { data, isLoading } = useQuery(
    ["patient-dashboard", patientId?.id],
    getPatientDasboardData,
    {
      enabled: Boolean(patientId?.id),
    }
  );

  return (
    <Box my={2}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        Session Analytics
      </Typography>
      <Grid container spacing={2}>
        <Grid item lg={4} md={6}>
          <AnalyticsCard
            icon={<WatchLaterIcon />}
            number={data?.data?.sessionDetails?.pending || 0}
            title="Pending Sessions"
            AvatarColor={theme.colors.warning.main}
            bg={theme.colors.warning.lighter}
            ChartColor={theme.colors.warning.light}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <AnalyticsCard
            icon={<SummarizeIcon />}
            number={data?.data?.sessionDetails?.approved || 0}
            title="Approve Sessions"
            AvatarColor={theme.colors.secondary.main}
            bg={theme.colors.secondary.lighter}
            ChartColor={theme.colors.secondary.light}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <AnalyticsCard
            icon={<WatchLaterIcon />}
            number={data?.data?.sessionDetails?.completed || 0}
            title="Completed Sessions"
            AvatarColor={theme.colors.primary.main}
            bg={theme.colors.primary.lighter}
            ChartColor={theme.colors.primary.light}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <AnalyticsCard
            icon={<WatchLaterIcon />}
            number={data?.data?.sessionDetails?.rejected || 0}
            title="Cancelled Sessions"
            AvatarColor={theme.colors.primary.main}
            bg={theme.colors.primary.lighter}
            ChartColor={theme.colors.primary.light}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <AnalyticsCard
            icon={<SummarizeIcon />}
            number={data?.data?.sessionInfo?.totalAmount || 0}
            title="Total Amount"
            AvatarColor={theme.colors.secondary.main}
            bg={theme.colors.secondary.lighter}
            ChartColor={theme.colors.secondary.light}
          />
        </Grid>
        <Grid item lg={4} md={6}>
          <AnalyticsCard
            icon={<SummarizeIcon />}
            number={data?.data?.sessionInfo?.totalReports || 0}
            title="Total School Reports"
            AvatarColor={theme.colors.secondary.main}
            bg={theme.colors.secondary.lighter}
            ChartColor={theme.colors.secondary.light}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SessionAnalytics;
