import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Box,
  Collapse,
  Grid,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import {
  getClinicianTimeSlots,
  updateTimeSlots,
} from "../../../../api/services/api";
import { SlotBox } from "../../../../components/SlotBox";

function TherapistSlotManagements() {
  const id = useParams();
  const queryClient = useQueryClient();
  const [openIndexes, setOpenIndexes] = useState<any>([]);

  const { data, isLoading } = useQuery(
    ["timeSlots", id?.id],
    getClinicianTimeSlots
  );

  let clinicianId = data?.data?.clinicianId;

  const { mutate } = useMutation(updateTimeSlots, {
    onSuccess: () => {
      queryClient.invalidateQueries("timeSlots");
    },
  });

  const handleDayChange = (newItem: any) => {
    mutate({ ...newItem, clinicianId, status: !newItem.status });
  };

  const handleChange = (newItem: any) => {
    mutate({ ...newItem, status: !newItem.status });
  };

  const handleClick = (index: any) => {
    setOpenIndexes((prevOpenIndexes: any) => {
      const isOpen = prevOpenIndexes.includes(index);
      return isOpen
        ? prevOpenIndexes.filter((i: any) => i !== index)
        : [...prevOpenIndexes, index];
    });
  };

  return (
    <>
      <Box
        border="1px solid #00000033"
        width="100%"
        borderRadius="10px 10px 0 0"
      >
        {data?.data?.days?.map((item: any, index: any) => (
          <>
            <Box>
              <Box
                sx={{
                  paddingX: "20px",
                  backgroundColor: "#0052CC08",
                  height: "60px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box key={index} display="flex" gap={2} alignItems="center">
                  <Typography
                    sx={{
                      width: "80px",
                      color: item?.status
                        ? "#rgba(18, 18, 18, 1)"
                        : "rgba(18, 18, 18, 0.4)",
                    }}
                  >
                    {item?.day}
                  </Typography>
                  <Switch
                    checked={item?.status}
                    onChange={() => handleDayChange(item)}
                  />
                </Box>
                <IconButton onClick={() => handleClick(index)}>
                  {openIndexes.includes(index) ? (
                    <ExpandLess />
                  ) : (
                    <ExpandMore />
                  )}
                </IconButton>
              </Box>
              <Collapse
                in={openIndexes.includes(index)}
                timeout="auto"
                unmountOnExit
              >
                {item?.timeOfDay?.map((itemLabel: any) => (
                  <Box p="30px">
                    <Typography mb={2}>{itemLabel?.label}</Typography>
                    <Grid container spacing={2}>
                      {itemLabel?.slots?.map((slot: any) => (
                        <Grid item lg={4}>
                          <SlotBox
                            checked={slot?.status}
                            onChange={() => handleChange(slot)}
                            startAt={slot?.startAt}
                            endAt={slot?.endAt}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                ))}
              </Collapse>
            </Box>
          </>
        ))}
      </Box>
    </>
  );
}

export default TherapistSlotManagements;
