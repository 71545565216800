import {
  Box,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../assets/index";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const SettingSideNav = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const menu = [
    {
      title: "Roles and Permissions",
      icon: icons.rolesIcn,
      path: "/settings/roles",
    },
    {
      title: "Users",
      icon: icons.usersIcn,
      path: "/settings/users",
    },
    {
      title: "Custom Notifications ",
      icon: icons.notificationIcn,
      path: "/settings/notifications",
    },
  ];

  return (
    <Box
      sx={{
        // boxShadow: "0px 6px 16px #0000001A",
        width: "350px",
        height: "100vh",
        overflow: "hidden",
        position: "fixed",
        backgroundColor: "#1A5C6E",
        color: "white",
        paddingY: "15px",
      }}
    >
      <Box mt={8.5}>
        <Box display="flex" gap={1} px="20px">
          <ArrowBackIcon
            onClick={() => navigate("/dashboard")}
            sx={{ cursor: "pointer" }}
          />
          <Typography
            variant="h5"
            fontSize="18px"
            textAlign="left"
            color="#FFFFFF"
          >
            Settings
          </Typography>
        </Box>
        <Divider sx={{ mt: 2, backgroundColor: "rgba(255, 255, 255, 0.4)" }} />
        <List sx={{ px: "20px" }}>
          {menu.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              style={{ textDecoration: "none", color: "initial" }}
            >
              <ListItemButton
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&.Mui-selected": {
                    background: "#c5901d",
                    borderRadius: "5px",
                  },
                  "&.Mui-selected:hover": {
                    background: "#c5901d",
                    borderRadius: "5px",
                  },
                  ":hover": {
                    background: "#a18343",
                  },
                  display: "flex",
                  alignItems: "center",
                  // height: "80px",
                  padding: "8px",
                  marginTop: "12px",
                }}
              >
                <ListItemIcon>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <img src={item.icon} alt="" width={20} height={20} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  color="white"
                  primary={
                    <Typography
                      variant="h6"
                      color="#FFFFFF"
                      fontSize="14.5px"
                      textAlign="left"
                    >
                      {item?.title}
                    </Typography>
                  }
                />
              </ListItemButton>
            </Link>
          ))}
        </List>
      </Box>
    </Box>
  );
};
