import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createPayouts,
  getClinicians,
  getPayoutStatistics,
  updatePayout,
} from "../../../api/services/api";

export const PayoutForm = ({ toggleDrawer, formData }: any) => {
  const [paymentDate, setPaymentDate] = useState<any>(
    formData?.paymentDate ? formData?.paymentDate : moment(new Date())
  );
  const [clinicianId, setClinicianId] = useState<any>("");
  const queryClient = useQueryClient();

  const { data: clinicianData } = useQuery("clinicians", getClinicians);

  const { data, isLoading } = useQuery(
    ["payoutsStats", clinicianId],
    getPayoutStatistics,
    {
      enabled: Boolean(clinicianId),
    }
  );

  const { mutate } = useMutation(createPayouts, {
    onSuccess: () => {
      toast.success("Payout created successfully");
      toggleDrawer(false);
      queryClient.invalidateQueries("payouts");
      queryClient.invalidateQueries("payoutStats");
    },
    onError: (error: any) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const { mutate: payoutUpdate } = useMutation(updatePayout, {
    onSuccess: () => {
      toast.success("Payout updated successfully");
      toggleDrawer(false);
      queryClient.invalidateQueries("payouts");
      queryClient.invalidateQueries("payoutStats");
    },
  });

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      clinicianId: formData?.clinician?.id,
      paymentReferenceId: formData?.paymentReferenceId,
      paymentDate: "",
      amount: formData?.amount,
      paymentMode: formData?.paymentMode,
    },
  });

  const onSubmit = (data: any) => {
    const res = {
      ...data,
      paymentDate: moment(paymentDate).format("YYYY-MM-DD"),
      amount: +data.amount,
    };

    if (formData?.id) {
      payoutUpdate({ data: res, id: formData?.id });
    } else {
      mutate(res);
    }
  };

  const paymentModes = [
    "Debit Card",
    "Credit Card",
    "Net Banking",
    "UPI",
    "Cash",
    "Cheque",
    "Other",
  ];

  const formatName = (name: any) => {
    if (!name) return "";
    return name
      .split(" ")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box width="550px" display="flex" flexDirection="column" gap="30px">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: "50px",
            backgroundColor: "rgba(250, 226, 148, 0.35)",
            borderRadius: "5px",
            px: "20px",
          }}
        >
          <Typography>Pending Amount</Typography>
          <Typography>{data?.data?.pendingPayout || 0}</Typography>
        </Box>
        <Controller
          control={control}
          name="clinicianId"
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id="clinicianId"
                options={clinicianData?.data?.clinicians}
                defaultValue={
                  formData?.clinicianId === ""
                    ? ""
                    : clinicianData?.data?.clinicians?.find(
                        (item: any) => item.id === formData?.clinicianId
                      )
                }
                getOptionLabel={(option) => formatName(option.user?.fullName)}
                renderInput={(params) => (
                  <TextField
                    required={true}
                    variant="standard"
                    {...params}
                    placeholder="Select Clinician"
                  />
                )}
                onChange={(_, data) => (
                  onChange(data?.id), setClinicianId(data?.id)
                )}
              />
            );
          }}
        />
        <TextField
          label="Payment Reference ID"
          variant={"standard"}
          fullWidth={true}
          {...register("paymentReferenceId", {
            required: "Payment Reference ID is required",
          })}
          error={!!errors.paymentReferenceId}
        />
        <FormControl component="fieldset" fullWidth={true}>
          {/* <Controller
render={({ field }) => ( */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
              <DatePicker
                label="Payment Date"
                inputFormat="DD/MM/YYYY"
                value={paymentDate}
                maxDate={moment(new Date())}
                onChange={(newValue: any) => {
                  setPaymentDate(
                    moment(new Date(newValue)).format("YYYY-MM-DD")
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"standard"}
                    error={!!errors.paymentDate}
                    fullWidth={true}
                    required={true}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </FormControl>
        <TextField
          label="Enter Payment"
          {...register("amount", {
            required: "Payment is required",
          })}
          error={!!errors.amount}
          variant={"standard"}
          fullWidth={true}
          type="number"
        />
        <Controller
          control={control}
          name="paymentMode"
          render={({ field: { onChange, value } }) => {
            return (
              <Autocomplete
                id="paymentMode"
                options={paymentModes}
                defaultValue={formData?.paymentMode}
                getOptionLabel={(option: any) => option}
                renderInput={(params) => (
                  <TextField
                    required={true}
                    variant="standard"
                    {...params}
                    placeholder="Select Payment Method"
                  />
                )}
                onChange={(_, data) => {
                  onChange(data);
                }}
              />
            );
          }}
        />
      </Box>
      <Button
        type="submit"
        disabled={data?.data?.pendingPayout === 0 ? true : false}
        fullWidth={true}
        variant="contained"
        sx={{ marginTop: "32px" }}
      >
        {formData ? "Update" : "Submit"}
      </Button>
    </form>
  );
};
