import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Radio,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import {
  getAvailableClinicians,
  getClinicians,
} from "../../../api/services/api";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import TherapistsEmptyScreen from "../Therapists/TherapistsEmptyScreen";
import SearchIcon from "@mui/icons-material/Search";
import textCapitalization from "../../../components/textCapitalization";
import EmptyScreen from "../../../components/EmptyScreen";

export const ChangeClinician = ({
  openClinicians,
  setOpenClinicians,
  handleChangeClinician,
  sessionData,
}: any) => {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [id, setId] = useState("");

  const { data, isLoading } = useQuery(
    [
      "available-clinicians",
      {
        search: search,
        date: sessionData?.date,
        timeslotIds: sessionData?.sessionTimeslots
          ?.map((item: any) => item?.timeslotId)
          .toString(),
        modeId: sessionData?.modeId,
        serviceId: sessionData?.serviceId,
        limit: limit,
        offset: limit * page,
      },
    ],
    getAvailableClinicians
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "user.fullName",
      key: "user.fullName",
    },

    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },

    {
      title: "Speciality",
      dataIndex: "designation",
      key: "designation",
      render: (colData: any) => {
        return colData?.designation ? colData?.designation : "NA";
      },
    },

    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
      render: (colData: any, row: any) => (
        <Typography>{colData} Year</Typography>
      ),
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <Box>
          <Radio
            checked={id === colData}
            value={colData}
            onChange={() => setId(colData)}
          />
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <Dialog
        fullWidth
        maxWidth={"md"}
        open={openClinicians}
        onClose={() => setOpenClinicians(false)}
      >
        <DialogTitle sx={{ fontSize: "20px" }}>Select Clinician</DialogTitle>
        <DialogContent dividers>
          <Box height="100%" width="100%">
            <TextField
              label="Search by Therapist Name"
              sx={{ width: "300px", padding: "0px", mb: 2 }}
              InputProps={{
                endAdornment: (
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                ),
              }}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {data?.data?.count === 0 ? (
              <EmptyScreen type="clinicians" />
            ) : (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Box>
                    <Table
                      key={"Therapists"}
                      dataSource={data?.data?.clinicians}
                      rowKey={"id"}
                      columns={columns}
                      loading={isLoading}
                    />

                    <Box
                      sx={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "right",
                      }}
                    >
                      <TablePagination
                        count={data?.data.count}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={limit}
                        onRowsPerPageChange={handleRowsPerPageChange}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
          <Box mt={2} display="flex" justifyContent="center">
            <Button
              onClick={() => handleChangeClinician(id)}
              sx={{ width: "250px" }}
              variant="contained"
            >
              {" "}
              Select Clinician
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
