import { Box, Card, Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  getAllPayouts,
  getSessions,
  getTopChargedClinicians,
  paymentAnalytics,
} from "../../../api/services/api";
import { AnalyticsCardTemp } from "../../../components/AnalyticsCard/AnalyticsCard";
import ColorTags from "../../../components/ColorTags";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";
import { PayoutAnalytics } from "./components/PayoutAnalytics";

export const PaymentsDashboard = () => {
  const theme = useTheme();
  const [status, setStatus] = useState([
    "PENDING",
    "APPROVED",
    "COMPLETED",
    "CANCELLED",
  ]);

  const { data: payoutData, isLoading } = useQuery("payout", paymentAnalytics);

  const { data: topChargedData } = useQuery(
    "top-clinicians",
    getTopChargedClinicians
  );

  const { data: sessionData } = useQuery(
    [
      "sessions",
      {
        status: status,
      },
    ],
    getSessions
  );

  const { data: payoutsData } = useQuery(["payouts"], getAllPayouts);

  const newPayoutData = payoutsData?.data?.payouts?.slice(0, 5);

  const dataMode = [
    {
      name: "Video",
      value: 600,
    },
    {
      name: "Audio",
      value: 300,
    },
    {
      name: "At Home",
      value: 500,
    },
  ];

  const dataType = [
    {
      name: "Speech Therapy",
      value: 600,
    },
    {
      name: "Language",
      value: 300,
    },
    {
      name: "Communication",
      value: 500,
    },
  ];

  const column = [
    {
      title: "Name",
      dataIndex: "user.fullName",
      key: "user.fullName",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (colData: any, row: any) => {
        return textCapitalization(colData || "");
      },
    },
    {
      title: "Speciality",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Total Payouts",
      dataIndex: "totalPayout",
      key: "totalPayout",
    },
  ];

  const sessionColumn = [
    {
      title: "Session ID",
      dataIndex: "sessionId",
      key: "sessionId",
    },

    {
      title: "Booked Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (colData: any) => {
        return moment(colData).format("DD-MM-YYYY");
      },
    },
    {
      title: "Session Date",
      dataIndex: "date",
      key: "date",
      render: (colData: any, row: any) => colData,
    },
    {
      title: "Time slot",
      dataIndex: "time",
      key: "time",
      render: (colData: any, row: any) => (
        <Box display="flex" justifyContent="center">
          {row?.sessionTimeslots?.map((item: any) => (
            <Typography>
              {item?.timeslot?.startAt} - {item?.timeslot?.endAt}
            </Typography>
          ))}
        </Box>
      ),
    },

    {
      title: "Patient Name",
      dataIndex: "patientProfile.fullName",
      key: "patientProfile.fullName",
    },

    {
      title: "Mode Of Consultation",
      dataIndex: "consultationMode",
      key: "consultationMode",
      render: (colData: any) => {
        return textCapitalization(colData);
      },
    },
    {
      title: "Price",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
    {
      title: "Tharpist Price",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];

  const payoutColumn = [
    {
      title: "Therapist Name",
      dataIndex: "clinician.user.fullName",
      key: "clinician.user.fullName",
    },
    {
      title: "Payment Reference ID",
      dataIndex: "paymentReferenceId",
      key: "paymentReferenceId",
    },

    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (colData: any) => moment(colData).format("DD-MMM-YYYY"),
    },
    {
      title: "Payment",
      dataIndex: "amount",
      key: "amount",
    },

    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
  ];

  const COLORS = ["#57CA22", "#FFA319", "#0052CC"];

  return (
    <Box mb={3}>
      <Typography mb={2} variant="h4">
        Payment Analytics
      </Typography>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={6}>
          <Box>
            <Box display="flex" gap={2}>
              <Card>
                <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
                  <Typography>Sessions by User</Typography>
                </Box>
                <AnalyticsCardTemp
                  number={payoutData?.data?.totalPayments || 0}
                  title="Total Amount"
                  AvatarName="user"
                  bg={theme.colors.warning.lighter}
                  ChartColor={theme.colors.warning.main}
                />
              </Card>
              <Card>
                <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
                  <Typography>Sessions by User</Typography>
                </Box>
                <AnalyticsCardTemp
                  number={payoutData?.data?.alsanCommission || 0}
                  title="ALSAN Commission"
                  AvatarName="user"
                  bg={theme.colors.warning.lighter}
                  ChartColor={theme.colors.warning.main}
                />
              </Card>
            </Box>
            <Box display="flex" gap={2} mt={2}>
              <Card>
                <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
                  <Typography>Sessions by User</Typography>
                </Box>
                <AnalyticsCardTemp
                  number={payoutData?.data?.totalPayouts || 0}
                  title="Total Payouts"
                  AvatarName="user"
                  bg={theme.colors.warning.lighter}
                  ChartColor={theme.colors.warning.main}
                />
              </Card>

              <Card>
                <Box p={2} sx={{ backgroundColor: "#fdf9f6" }}>
                  <Typography>Sessions by User</Typography>
                </Box>
                <AnalyticsCardTemp
                  number={payoutData?.data?.pendingPayouts || 0}
                  title="Pending Payouts"
                  AvatarName="user"
                  bg={theme.colors.warning.lighter}
                  ChartColor={theme.colors.warning.main}
                />
              </Card>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box
            p={2}
            sx={{ backgroundColor: "#fdf9f6", borderRadius: "10px 10px 0 0" }}
          >
            <Typography>Top 5 Patients</Typography>
          </Box>
          <Table
            headAlign={"center"}
            tableAlign={"center"}
            key={"sessions"}
            dataSource={
              topChargedData?.data?.clinicians?.length === 0
                ? null
                : topChargedData?.data?.clinicians
            }
            rowKey={"id"}
            columns={column}
          />
        </Grid>
      </Grid>
      <Typography mt={4} mb={2} variant="h4">
        Payout Analytics
      </Typography>
      <Card sx={{ width: "100%", height: "400px" }}>
        <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
          <Typography>Sessions by User</Typography>
        </Box>
        <PayoutAnalytics />
      </Card>
      {/* <Typography mt={4} variant="h4">
        Payout by Modes type
      </Typography>
      <Grid mt={1} container spacing={2}>
        <Grid item xs={6}>
          <Card sx={{ width: "100%", height: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
              <Typography>Sessions by User</Typography>
            </Box>
            <Box width="100%" height="85%" display="flex">
              <ResponsiveContainer width="65%" height="100%">
                <PieChart>
                  <Pie
                    data={dataMode}
                    dataKey="value"
                    nameKey="name"
                    cx="40%"
                    cy="50%"
                    innerRadius={90}
                    outerRadius={110}
                    fill="color"
                  >
                    {dataMode.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                justifyContent="center"
              >
                <ColorTags color="#57CA22" tag="Video" />
                <ColorTags color="#FFA319" tag="Audio" />
                <ColorTags color="#0052CC" tag="At Home" />
              </Box>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ width: "100%", height: "100%" }}>
            <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
              <Typography>Sessions by User</Typography>
            </Box>
            <Box width="100%" height="85%" display="flex">
              <ResponsiveContainer width="65%" height="100%">
                <PieChart>
                  <Pie
                    data={dataType}
                    dataKey="value"
                    nameKey="name"
                    cx="40%"
                    cy="50%"
                    outerRadius={110}
                    fill="#57CA22"
                  >
                    {dataType.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
              <Box
                display="flex"
                flexDirection="column"
                gap={1.5}
                justifyContent="center"
              >
                <ColorTags color="#57CA22" tag="Speech Therapy" />
                <ColorTags color="#FFA319" tag="Language" />
                <ColorTags color="#0052CC" tag="Communication" />
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid> */}
      <Typography mt={4} mb={2} variant="h4">
        Payout by Modes type
      </Typography>
      <Table
        headAlign={"center"}
        tableAlign={"center"}
        key={"sessions"}
        dataSource={
          sessionData?.data?.count === 0 ? null : sessionData?.data?.clinicians
        }
        rowKey={"id"}
        columns={sessionColumn}
      />
      <Typography mt={4} mb={2} variant="h4">
        Session Payouts
      </Typography>
      <Table
        headAlign={"center"}
        tableAlign={"center"}
        key={"sessions"}
        dataSource={payoutsData?.data?.count === 0 ? null : newPayoutData}
        rowKey={"id"}
        columns={payoutColumn}
      />
    </Box>
  );
};
