import audioIcon from "./icons/audioIcon.png";
import clinicians from "./icons/clinicians.png";
import dobIcn from "./icons/dob.png";
import emailIcn from "./icons/email.png";
import experienceIcn from "./icons/experience.png";
import genderIcn from "./icons/gender.png";
import graduateIcn from "./icons/graduate.png";
import homeIcon from "./icons/homeIcon.png";
import mobileIcn from "./icons/mobile.png";
import notificationIcn from "./icons/notification.png";
import patientIcn from "./icons/patients.png";
import payoutInc from "./icons/payouts.png";
import reportsInc from "./icons/reports.png";
import rolesIcn from "./icons/roles.png";
import user from "./icons/user.png";
import usersIcn from "./icons/users.png";
import videoIcon from "./icons/videoCall.png";
import Block from "./images/block.png";
import Clock from "./images/clock.png";
import DeleteIcn from "./images/deleteIcn.png";
import EditIcn from "./images/editIcn.png";
import EmptyImage from "./images/EmptyImage.png";
import HideIcon from "./images/eye-crossed.png";
import language from "./images/language.png";
import AlsanLogo from "./images/logo.png";
import More from "./images/more.png";
import Notifications from "./images/notifications.png";
import Patients from "./images/Patients.png";
import ProfileAvatar from "./images/profileAvatar.png";
import Resources from "./images/Resources.png";
import service from "./images/service.png";
import serviceIcon from "./images/serviceIcon.png";
import SettingsIcon from "./images/settings.png";
import SignupPic from "./images/SignupLayoutPic.png";
import symptom from "./images/symptom.png";
import Therapist from "./images/therapist.png";
import Wallet from "./images/wallet.png";
import Youtube from "./images/youtube.png";

export const icons = {
  audioIcon,
  homeIcon,
  serviceIcon,
  language,
  service,
  symptom,
  clinicians,
  patientIcn,
  user,
  Block,
  Clock,
  SettingsIcon,
  Notifications,
  Wallet,
  Therapist,
  ProfileAvatar,
  Patients,
  Resources,
  Youtube,
  More,
  EditIcn,
  HideIcon,
  DeleteIcn,
  graduateIcn,
  mobileIcn,
  emailIcn,
  experienceIcn,
  dobIcn,
  genderIcn,
  payoutInc,
  reportsInc,
  rolesIcn,
  usersIcn,
  notificationIcn,
  videoIcon,
};

export { AlsanLogo, SignupPic, EmptyImage };
