import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { getSessions } from "../../../../api/services/api";
import EmptyScreen from "../../../../components/EmptyScreen";
import { ViewButton } from "../../../../components/IconButtons";
import Loader from "../../../../components/Loader";
import Table from "../../../../components/TableComponent";
import textCapitalization from "../../../../components/textCapitalization";

export const TherapistSessions = () => {
  const navigate = useNavigate();
  const id = useParams();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [modeValue, setModeValue] = useState("");
  const [status, setStatus] = useState();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const { data, isLoading } = useQuery(
    [
      "sessions",
      {
        status: [status],
        search: search,
        modeOfConsultation: modeValue.toUpperCase(),
        fromDate: startDate,
        toDate: endDate,
        clinicianId: id?.id,
        limit: limit,
        offset: limit * page,
      },
    ],
    getSessions
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Session ID",
      dataIndex: "sessionId",
      key: "sessionId",
    },

    {
      title: "Client Name",
      dataIndex: "patientProfile.fullName",
      key: "patientProfile.fullName",
    },

    {
      title: "Time slot",
      dataIndex: "time",
      key: "time",
      render: (colData: any, row: any) => (
        <Box display="flex" justifyContent="center">
          {row?.sessionTimeslots?.map((item: any) => (
            <Typography sx={{ width: "100px" }}>
              {item?.timeslot?.startAt} - {item?.timeslot?.endAt}
            </Typography>
          ))}
        </Box>
      ),
    },

    {
      title: "Mode Of Consultation",
      dataIndex: "consultationMode",
      key: "consultationMode",
      render: (colData: any) => {
        return textCapitalization(colData);
      },
    },

    {
      title: "Price",
      dataIndex: "sessionPayment",
      key: "sessionPayment",
      render: (colData: any) => {
        return <Typography>د.إ {colData?.totalAmount}</Typography>;
      },
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (colData: any) => {
        return (
          <Box
            sx={{
              borderRadius: "10px",
              fontFamily: "Inter_semibold",
              fontSize: "14px",
              px: 1.5,
              py: 1,
              background:
                colData === "PENDING"
                  ? `${theme.colors.warning.lighter}`
                  : colData === "APPROVED"
                  ? `${theme.colors.success.lighter}`
                  : colData === "COMPLETED"
                  ? `rgba(0, 128, 0, 0.1)`
                  : colData === "REPORT_SUBMITTED"
                  ? `rgba(128, 0, 128, 0.1)`
                  : colData === "CANCELLED"
                  ? `${theme.colors.error.lighter}`
                  : colData === "REJECTED"
                  ? `${theme.colors.error.lighter}`
                  : colData === "PAID"
                  ? `rgba(0, 128, 128, 0.1)`
                  : colData === "STARTED"
                  ? `rgba(255, 140, 0, 0.1)`
                  : colData === "NEW_CLINICIAN_ASSIGNED"
                  ? `rgba(255, 165, 0, 0.1)`
                  : colData === "CLINICIAN_ACCEPTED"
                  ? `rgba(65, 105, 225, 0.1)`
                  : colData === "CLINICIAN_REJECTED"
                  ? `${theme.colors.error.lighter}`
                  : "",
              color:
                colData === "PENDING"
                  ? `${theme.colors.warning.main}`
                  : colData === "APPROVED"
                  ? `${theme.colors.success.main}`
                  : colData === "COMPLETED"
                  ? `#008000`
                  : colData === "REPORT_SUBMITTED"
                  ? `#800080`
                  : colData === "CANCELLED"
                  ? `${theme.colors.error.main}`
                  : colData === "REJECTED"
                  ? `${theme.colors.error.main}`
                  : colData === "PAID"
                  ? `#008080`
                  : colData === "STARTED"
                  ? `#FF8C00`
                  : colData === "NEW_CLINICIAN_ASSIGNED"
                  ? `#FFA500`
                  : colData === "CLINICIAN_ACCEPTED"
                  ? `#4169E1`
                  : colData === "CLINICIAN_REJECTED"
                  ? `${theme.colors.error.main}`
                  : "",
            }}
          >
            {colData}
          </Box>
        );
      },
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <ViewButton
          onClick={() => {
            return navigate(`/sessions/${row.id}`);
          }}
        />
      ),
    },
  ];

  const modesMenu = [
    {
      mode: "Video",
    },
    {
      mode: "Audio",
    },
    {
      mode: "Home",
    },
  ];
  const statusMenu = ["PENDING", "APPROVED", "COMPLETED", "CANCELLED"];

  return (
    <Box>
      <Box display="flex" gap="20px">
        <TextField
          label="Search by Session Id"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button
          variant="outlined"
          startIcon={<FilterListOutlinedIcon />}
          onClick={handleClick}
        >
          <Typography>Filter</Typography>
        </Button>
        <Menu
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                inputFormat="DD/MM/YYYY"
                value={startDate}
                onChange={(newValue: any) => {
                  setStartDate(moment(new Date(newValue)).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: "230px", mr: 1 }} {...params} />
                )}
              />
              <DatePicker
                label="End Date"
                inputFormat="DD/MM/YYYY"
                value={endDate}
                onChange={(newValue: any) => {
                  setEndDate(moment(new Date(newValue)).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField sx={{ width: "230px" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </MenuItem>

          <MenuItem>
            <Box sx={{ width: "100%" }}>
              <Autocomplete
                options={modesMenu}
                value={modesMenu.find((option) => option?.mode == modeValue)}
                getOptionLabel={(option) => option.mode}
                isOptionEqualToValue={(option, value) =>
                  option.mode === value?.mode
                }
                onChange={(event, newValue) => {
                  setModeValue(newValue?.mode || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="Mode of Sessions"
                  />
                )}
              />
            </Box>
          </MenuItem>
          <MenuItem>
            <Box sx={{ width: "100%" }}>
              <Autocomplete
                options={statusMenu}
                getOptionLabel={(option) => textCapitalization(option)}
                value={status}
                onChange={(event, newValue: any) => {
                  setStatus(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant="outlined"
                    label="Status"
                  />
                )}
              />
            </Box>
          </MenuItem>
        </Menu>
      </Box>
      {data?.data.count === 0 ? (
        <EmptyScreen type="Therapist Sessions" />
      ) : (
        <>
          <Box mt={2}>
            {data?.data.length == 0 ? (
              <EmptyScreen type="Sessions" />
            ) : (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Box>
                    <Table
                      headAlign={"center"}
                      tableAlign={"center"}
                      key={"sessions"}
                      dataSource={data?.data?.sessions}
                      rowKey={"id"}
                      columns={columns}
                      loading={isLoading}
                    />
                    <Box
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TablePagination
                        count={data?.data.count}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={limit}
                        onRowsPerPageChange={handleRowsPerPageChange}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
