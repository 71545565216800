import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createSymptoms, updateSymptoms } from "../../../api/services/api";

export const SymptomForm = ({ toggleDrawer, formData }: any) => {
  const queryClient = useQueryClient();

  const { mutate: addSymptom } = useMutation(createSymptoms, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("symptoms");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const { mutate: EditSymptom } = useMutation(updateSymptoms, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("symptoms");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: formData?.title,
      arabic: formData?.arabic,
    },
  });

  console.log(formData);

  const onSubmit = (data: any) => {
    const res = {
      ...data,
      title: data?.title,
      arabic: data?.arabic,
    };
    if (formData === null) {
      addSymptom(res);
    } else {
      EditSymptom({ body: res, id: formData?.id });
    }
  };

  return (
    <Box width="450px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Symptom Name (English)"
          fullWidth
          variant="standard"
          {...register("title", {
            required: "Symptom Name is required",
          })}
          error={Boolean(errors.title)}
          helperText={!errors.title?.message}
        />
        <TextField
          label="Symptom Name (Arabic)"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("arabic", {
            required: "Symptom Name is required",
          })}
          error={Boolean(errors.arabic)}
          helperText={!errors.arabic?.message}
        />

        <Button type="submit" sx={{ mt: 2 }} fullWidth variant="contained">
          Submit
        </Button>
      </form>
    </Box>
  );
};
