//@ts-ignore
import loadable from "@loadable/component";
import { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import PrimaryLayout from "../layout/adminLayout/primaryLayout";
import Dashboard from "../pages/admin/Dashboard/Dashboard";
import PatientDetails from "../pages/admin/Patients/PatientDetails";
import BasicDetails from "../pages/admin/Patients/PatientDetails/BasicDetails";
import Reviews from "../pages/admin/Patients/PatientDetails/Reviews";
import SessionAnalytics from "../pages/admin/Patients/PatientDetails/SessionAnalytics";
import Patients from "../pages/admin/Patients/Patients";
import Resources from "../pages/admin/Resources";
import Articles from "../pages/admin/Resources/Articles";
import Videos from "../pages/admin/Resources/Videos";
import TherapistDetails from "../pages/admin/Therapists/TherapistDetails";
import TotalAnalytics from "../pages/admin/Therapists/TherapistDetails/TotalAnalytics";
import Therapists from "../pages/admin/Therapists/Therapists";

import { PatientReports } from "../pages/admin/Patients/PatientDetails/PatientReports";
import TherapistPatients from "../pages/admin/Therapists/TherapistDetails/TherapistPatients";
import TherapistPayouts from "../pages/admin/Therapists/TherapistDetails/TherapistPayouts";
import TherapistReviews from "../pages/admin/Therapists/TherapistDetails/TherapistReviews";
import TherapistSlotManagements from "../pages/admin/Therapists/TherapistDetails/TherapistSlotManagements";
import PrivacyPolicy from "../pages/PrivacyPolicy";

import SettingsLayout from "../layout/settingLayout";
import { ConsultationMode } from "../pages/admin/ConsultationMode";
import { PaymentsDashboard } from "../pages/admin/Dashboard/PaymentsDashboard";
import { Sessions } from "../pages/admin/Patients/PatientDetails/Sessions";
import { Payouts } from "../pages/admin/Payouts";
import { Reports } from "../pages/admin/Reports";
import { Services } from "../pages/admin/Services";
import { SessionsList } from "../pages/admin/Sessions/SessionsList";
import { SessionView } from "../pages/admin/Sessions/SessionView";
import { Symptoms } from "../pages/admin/Symptoms";
import { TherapistReports } from "../pages/admin/Therapists/TherapistDetails/TherapistReports";
import { TherapistSessions } from "../pages/admin/Therapists/TherapistDetails/TherapistSessions";
import { EditRoles } from "../pages/Settings/EditRoles";
import { Notification } from "../pages/Settings/Notification";
import { Roles } from "../pages/Settings/Roles";
import { Users } from "../pages/Settings/Users";

const Signup = loadable(() => import("../pages/SignUp"));
const Login = loadable(() => import("../pages/Login"));
const ForgotPassword = loadable(() => import("../pages/ForgotPassword"));
const OTPVerification = loadable(() => import("../pages/OTPVerification"));

const AdminRoutesContainer = () => {
  const navigate = useNavigate();

  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    const authenticate = localStorage.getItem("token") ? true : false;
    !authenticate && navigate("/login", { replace: true });
  }, []);

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="otp-verification" element={<OTPVerification />} />
      <Route path="settings" element={<SettingsLayout />}>
        <Route index element={<Navigate to="roles" />} />
        <Route path="roles" element={<Roles />} />
        <Route path="roles/:id/edit-role" element={<EditRoles />} />
        <Route path="roles/new-role" element={<EditRoles />} />
        {/* <Route path="users" element={<Users />} /> */}
        <Route path="notifications" element={<Notification />} />
      </Route>
      <Route path="/" element={<PrimaryLayout />}>
        <Route index element={<Navigate to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="payments-dashboard" element={<PaymentsDashboard />} />
        <Route path="sessions" element={<SessionsList />} />
        <Route path="sessions/:id" element={<SessionView />} />
        <Route path="therapists" element={<Therapists />} />
        <Route path="therapists/details" element={<TherapistDetails />}>
          <Route path=":id/dashboard" element={<TotalAnalytics />} />
          <Route path=":id/sessions" element={<TherapistSessions />} />
          <Route path=":id/payouts" element={<TherapistPayouts />} />
          <Route path=":id/reports" element={<TherapistReports />} />
          <Route path=":id/reviews" element={<TherapistReviews />} />
          <Route path=":id/patients" element={<TherapistPatients />} />
          <Route
            path=":id/slot-management"
            element={<TherapistSlotManagements />}
          />
        </Route>
        <Route path="patients" element={<Patients />} />
        <Route path="patients/details" element={<PatientDetails />}>
          <Route path=":id/dashboard" element={<SessionAnalytics />} />
          <Route path=":id/basic-details" element={<BasicDetails />} />
          <Route path=":id/sessions" element={<Sessions />} />
          <Route path=":id/reports" element={<PatientReports />} />
          <Route path=":id/reviews" element={<Reviews />} />
        </Route>
        <Route path="payouts" element={<Payouts />} />
        <Route path="reports" element={<Reports />} />

        <Route path="resources" element={<Resources />}>
          <Route path="" element={<Videos />} />
          <Route path="articles" element={<Articles />} />
        </Route>
        <Route path="services" element={<Services />} />
        <Route path="consultationModes" element={<ConsultationMode />} />
        <Route path="symptoms" element={<Symptoms />} />
        <Route path="users" element={<Users />} />
      </Route>
      <Route path="privacy-policy" element={<PrivacyPolicy />} />
    </Routes>
  );
};

export default AdminRoutesContainer;
