import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  TablePagination,
  TextField,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getClinicianPatients } from "../../../../api/services/api";
import EmptyScreen from "../../../../components/EmptyScreen";
import Loader from "../../../../components/Loader";
import Table from "../../../../components/TableComponent";

function TherapistPatients() {
  const id = useParams();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const { data, isLoading, isError, error } = useQuery(
    [
      "patientSessions",
      id?.id,
      {
        search: search,
        limit: limit,
        offset: limit * page,
      },
    ],
    getClinicianPatients
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
    },
    {
      title: "Number of Sessions",
      dataIndex: "sessionsCount",
      key: "sessionsCount",
    },
  ];
  return (
    <Box>
      <Box display="flex" gap="20px">
        <TextField
          label="Search by Name"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      {data?.data.length === 0 ? (
        <EmptyScreen type="Therapist Sessions" />
      ) : (
        <>
          <Box mt={2}>
            {data?.data.length == 0 ? (
              <EmptyScreen type="Sessions" />
            ) : (
              <>
                {isLoading ? (
                  <Loader />
                ) : (
                  <Box>
                    <Table
                      headAlign={"center"}
                      tableAlign={"center"}
                      key={"patientSessions"}
                      dataSource={data?.data?.patients}
                      rowKey={"id"}
                      columns={columns}
                      loading={isLoading}
                    />
                    <Box
                      sx={{
                        marginTop: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <TablePagination
                        count={data?.data.count}
                        page={page}
                        onPageChange={handlePageChange}
                        rowsPerPage={limit}
                        onRowsPerPageChange={handleRowsPerPageChange}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
}

export default TherapistPatients;
