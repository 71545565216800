import React from "react";

export const ViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.026"
      height="14.775"
      viewBox="0 0 19.026 14.775"
    >
      <g id="eye_9_" data-name="eye (9)" transform="translate(0.152 0.1)">
        <path
          id="Path_1389"
          data-name="Path 1389"
          d="M18.147,7.93a10.328,10.328,0,0,0-8.79-5.275A10.328,10.328,0,0,0,.568,7.93a3.827,3.827,0,0,0,0,4.026,10.328,10.328,0,0,0,8.79,5.275,10.328,10.328,0,0,0,8.79-5.275A3.827,3.827,0,0,0,18.147,7.93Zm-1.33,3.209a8.823,8.823,0,0,1-7.46,4.532A8.823,8.823,0,0,1,1.9,11.139a2.276,2.276,0,0,1,0-2.393,8.823,8.823,0,0,1,7.46-4.532,8.817,8.817,0,0,1,7.46,4.532A2.276,2.276,0,0,1,16.817,11.139Z"
          transform="translate(0.004 -2.655)"
          fill="#878787"
          stroke="#fff"
          strokeWidth="0.2"
        />
        <path
          id="Path_1390"
          data-name="Path 1390"
          d="M10.9,7a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,10.9,7Zm0,6.239a2.34,2.34,0,1,1,2.34-2.34A2.34,2.34,0,0,1,10.9,13.239Z"
          transform="translate(-1.538 -3.612)"
          fill="#878787"
          stroke="#fff"
          strokeWidth="0.2"
        />
      </g>
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      id="Component_49_29"
      data-name="Component 49 – 29"
      xmlns="http://www.w3.org/2000/svg"
      width="16.716"
      height="16.716"
      viewBox="0 0 16.716 16.716"
    >
      <path
        id="pencil_1_"
        data-name="pencil (1)"
        d="M14.335.665a2.275,2.275,0,0,0-3.214,0l-10.2,10.2A3.116,3.116,0,0,0,0,13.085v1.288A.627.627,0,0,0,.627,15H1.915a3.115,3.115,0,0,0,2.217-.918l10.2-10.2a2.275,2.275,0,0,0,0-3.214ZM3.246,13.195a1.9,1.9,0,0,1-1.33.551H1.255v-.661a1.869,1.869,0,0,1,.551-1.33L9.549,4.012l1.443,1.443Zm10.2-10.2L11.876,4.564l-1.443-1.44,1.573-1.573a1.019,1.019,0,0,1,1.44,1.443Z"
        transform="translate(0 1.828) rotate(-7)"
        fill="#D58E4E"
      />
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      id="trash_4_"
      data-name="trash (4)"
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="18"
      viewBox="0 0 15 18"
    >
      <g
        id="trash_4_2"
        data-name="trash (4)"
        transform="translate(-2)"
        opacity="0.5"
      >
        <path
          id="Path_1384"
          data-name="Path 1384"
          d="M16.25,3H13.925A3.757,3.757,0,0,0,10.25,0H8.75A3.757,3.757,0,0,0,5.075,3H2.75a.75.75,0,1,0,0,1.5H3.5v9.75A3.755,3.755,0,0,0,7.25,18h4.5a3.755,3.755,0,0,0,3.75-3.75V4.5h.75a.75.75,0,0,0,0-1.5ZM8.75,1.5h1.5A2.254,2.254,0,0,1,12.372,3H6.628A2.255,2.255,0,0,1,8.75,1.5ZM14,14.25a2.25,2.25,0,0,1-2.25,2.25H7.25A2.25,2.25,0,0,1,5,14.25V4.5h9Z"
          fill="#121212"
        />
        <path
          id="Path_1385"
          data-name="Path 1385"
          d="M9.75,16a.75.75,0,0,0,.75-.75v-4.5a.75.75,0,1,0-1.5,0v4.5A.75.75,0,0,0,9.75,16Z"
          transform="translate(-1.75 -2.5)"
          fill="#121212"
        />
        <path
          id="Path_1386"
          data-name="Path 1386"
          d="M13.75,16a.75.75,0,0,0,.75-.75v-4.5a.75.75,0,1,0-1.5,0v4.5A.75.75,0,0,0,13.75,16Z"
          transform="translate(-2.75 -2.5)"
          fill="#121212"
        />
      </g>
    </svg>
  );
};

export const DisabledEditIcon = () => {
  return (
    <svg
      id="Component_13_1"
      data-name="Component 13 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
    >
      <g id="Group_77" data-name="Group 77" transform="translate(-1753 -331)">
        <path
          id="Path_1387"
          data-name="Path 1387"
          d="M14.341.65,6,8.99a3.4,3.4,0,0,0-1,2.418v.919a.684.684,0,0,0,.684.684H6.6a3.4,3.4,0,0,0,2.418-1L17.36,3.669a2.138,2.138,0,0,0,0-3.019,2.185,2.185,0,0,0-3.019,0ZM16.393,2.7,8.053,11.042a2.066,2.066,0,0,1-1.451.6H6.368v-.235a2.066,2.066,0,0,1,.6-1.451l8.339-8.339a.785.785,0,0,1,1.085,0,.768.768,0,0,1,0,1.085Z"
          transform="translate(1751.42 330.986)"
          fill="rgba(18,18,18,0.5)"
        />
        <path
          id="Path_1388"
          data-name="Path 1388"
          d="M15.732,6.142a.684.684,0,0,0-.684.684V10.26H12.312a2.052,2.052,0,0,0-2.052,2.052v2.736H3.42A2.052,2.052,0,0,1,1.368,13V3.42A2.052,2.052,0,0,1,3.42,1.368H9.6A.684.684,0,0,0,9.6,0H3.42A3.424,3.424,0,0,0,0,3.42V13a3.424,3.424,0,0,0,3.42,3.42h7.759a3.4,3.4,0,0,0,2.419-1L15.414,13.6a3.4,3.4,0,0,0,1-2.418V6.826A.684.684,0,0,0,15.732,6.142Zm-3.1,8.306a2.035,2.035,0,0,1-1,.547V12.312a.684.684,0,0,1,.684-.684H15a2.063,2.063,0,0,1-.547,1Z"
          transform="translate(1753 331)"
          fill="rgba(18,18,18,0.5)"
        />
      </g>
      <g id="Group_79" data-name="Group 79" transform="translate(-1753 -330)">
        <circle
          id="Ellipse_66"
          data-name="Ellipse 66"
          cx="5"
          cy="5"
          r="5"
          transform="translate(1763 340)"
          fill="#bc2c3d"
        />
        <path
          id="cross-small_3_"
          data-name="cross-small (3)"
          d="M11.181,5.838h0a.445.445,0,0,0-.63,0L8.509,7.88,6.467,5.838a.445.445,0,0,0-.63,0h0a.445.445,0,0,0,0,.63L7.88,8.509,5.838,10.551a.445.445,0,0,0,0,.63h0a.445.445,0,0,0,.63,0L8.509,9.139l2.042,2.042a.445.445,0,0,0,.63,0h0a.445.445,0,0,0,0-.63L9.139,8.509l2.042-2.042A.445.445,0,0,0,11.181,5.838Z"
          transform="translate(1759.491 336.491)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export const DisabledDeleteIcon = () => {
  return (
    <svg
      id="Component_14_1"
      data-name="Component 14 – 1"
      xmlns="http://www.w3.org/2000/svg"
      width="19.584"
      height="21"
      viewBox="0 0 19.584 21"
    >
      <g
        id="Group_78"
        data-name="Group 78"
        transform="translate(-1789.416 -330)"
      >
        <g
          id="trash_4_"
          data-name="trash (4)"
          transform="translate(1787.416 330)"
          opacity="0.5"
        >
          <path
            id="Path_1384"
            data-name="Path 1384"
            d="M16.25,3H13.925A3.757,3.757,0,0,0,10.25,0H8.75A3.757,3.757,0,0,0,5.075,3H2.75a.75.75,0,1,0,0,1.5H3.5v9.75A3.755,3.755,0,0,0,7.25,18h4.5a3.755,3.755,0,0,0,3.75-3.75V4.5h.75a.75.75,0,0,0,0-1.5ZM8.75,1.5h1.5A2.254,2.254,0,0,1,12.372,3H6.628A2.255,2.255,0,0,1,8.75,1.5ZM14,14.25a2.25,2.25,0,0,1-2.25,2.25H7.25A2.25,2.25,0,0,1,5,14.25V4.5h9Z"
            fill="#121212"
          />
          <path
            id="Path_1385"
            data-name="Path 1385"
            d="M9.75,16a.75.75,0,0,0,.75-.75v-4.5a.75.75,0,1,0-1.5,0v4.5A.75.75,0,0,0,9.75,16Z"
            transform="translate(-1.75 -2.5)"
            fill="#121212"
          />
          <path
            id="Path_1386"
            data-name="Path 1386"
            d="M13.75,16a.75.75,0,0,0,.75-.75v-4.5a.75.75,0,1,0-1.5,0v4.5A.75.75,0,0,0,13.75,16Z"
            transform="translate(-2.75 -2.5)"
            fill="#121212"
          />
        </g>
      </g>
      <g
        id="Group_80"
        data-name="Group 80"
        transform="translate(-1753.416 -329)"
      >
        <circle
          id="Ellipse_66"
          data-name="Ellipse 66"
          cx="5"
          cy="5"
          r="5"
          transform="translate(1763 340)"
          fill="#bc2c3d"
        />
        <path
          id="cross-small_3_"
          data-name="cross-small (3)"
          d="M11.181,5.838h0a.445.445,0,0,0-.63,0L8.509,7.88,6.467,5.838a.445.445,0,0,0-.63,0h0a.445.445,0,0,0,0,.63L7.88,8.509,5.838,10.551a.445.445,0,0,0,0,.63h0a.445.445,0,0,0,.63,0L8.509,9.139l2.042,2.042a.445.445,0,0,0,.63,0h0a.445.445,0,0,0,0-.63L9.139,8.509l2.042-2.042A.445.445,0,0,0,11.181,5.838Z"
          transform="translate(1759.491 336.491)"
          fill="#fff"
        />
      </g>
    </svg>
  );
};

export const PrintIcon = () => {
  return (
    <svg
      id="print_1_"
      data-name="print (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <g id="print_1_2" data-name="print (1)" opacity="0.5">
        <path
          id="Path_1768"
          data-name="Path 1768"
          d="M12.667,4V2.667A2.667,2.667,0,0,0,10,0H6A2.667,2.667,0,0,0,3.333,2.667V4A3.337,3.337,0,0,0,0,7.333v3.333A3.337,3.337,0,0,0,3.333,14a2,2,0,0,0,2,2h5.333a2,2,0,0,0,2-2A3.337,3.337,0,0,0,16,10.667V7.333A3.337,3.337,0,0,0,12.667,4Zm-8-1.333A1.333,1.333,0,0,1,6,1.333h4a1.333,1.333,0,0,1,1.333,1.333V4H4.667ZM11.333,14a.667.667,0,0,1-.667.667H5.333A.667.667,0,0,1,4.667,14V11.333a.667.667,0,0,1,.667-.667h5.333a.667.667,0,0,1,.667.667Zm3.333-3.333a2,2,0,0,1-2,2V11.333a2,2,0,0,0-2-2H5.333a2,2,0,0,0-2,2v1.333a2,2,0,0,1-2-2V7.333a2,2,0,0,1,2-2h9.333a2,2,0,0,1,2,2Z"
          fill="#121212"
        />
        <path
          id="Path_1769"
          data-name="Path 1769"
          d="M17,10H15.667a.667.667,0,1,0,0,1.333H17A.667.667,0,1,0,17,10Z"
          transform="translate(-5 -3.333)"
        />
      </g>
    </svg>
  );
};

export const DisableIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g
        id="vuesax_twotone_slash"
        data-name="vuesax/twotone/slash"
        transform="translate(-612 -337)"
      >
        <g id="slash" transform="translate(612 337)">
          <path
            id="Vector"
            d="M8.75,0,0,8.75"
            transform="translate(3.063 3.125)"
            fill="none"
            stroke="#ff3465"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M0,0H15V15H0Z"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M6.25,12.5A6.25,6.25,0,1,0,0,6.25,6.252,6.252,0,0,0,6.25,12.5Z"
            transform="translate(1.25 1.25)"
            fill="none"
            stroke="#ff3465"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export const EnableIcon = () => {
  return (
    <svg
      id="Group_20860"
      data-name="Group 20860"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        id="Path_40775"
        data-name="Path 40775"
        d="M0,0H18V18H0Z"
        fill="none"
      />
      <circle
        id="Ellipse_1005"
        data-name="Ellipse 1005"
        cx="7"
        cy="7"
        r="7"
        transform="translate(2 2)"
        fill="none"
        stroke="#57ca22"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.3"
      />
      <path
        id="Path_40776"
        data-name="Path 40776"
        d="M9,11.5,10.5,13l3-3"
        transform="translate(-2.25 -2.5)"
        fill="none"
        stroke="#57ca22"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.3"
      />
    </svg>
  );
};
