import {
  Box,
  Button,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createConsultationMode,
  updateConsultationMode,
} from "../../../api/services/api";
import ImageUpload from "../../../components/ImageUpload";

export const ConsultationModeForm = ({ toggleDrawer, formData }: any) => {
  const queryClient = useQueryClient();
  const [selectedMode, setSelectedMode] = useState<string>(
    formData?.type ?? ""
  );
  const [picDetails, setPicDetails] = useState<any>(null);
  console.log(picDetails, "Check PIC");
  const [editImage, setEditImage] = useState(formData?.imageUrl);

  const consultationModes = ["AUDIO", "VIDEO", "HOME"];

  const handleChangeMode = (e: any) => {
    setSelectedMode(e.target.value);
  };

  const { mutate: postService } = useMutation(createConsultationMode, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("mode-of-consultation");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const { mutate: EditServices } = useMutation(updateConsultationMode, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("mode-of-consultation");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: formData?.title,
      price: formData?.price,
      type: formData?.type,
      imageUrl: formData?.imageUrl,
    },
  });

  console.log(formData);

  const onSubmit = (data: any) => {
    console.log(data, "Test Data");
    const res = {
      ...data,
      imageUrl: picDetails?.key || formData?.imageUrl,
      type: selectedMode,
      price: data?.price || formData?.price,
      title: data?.title || formData?.title,
    };

    if (!formData?.imageUrl && !picDetails) {
      return toast.error("Image should not be empty");
    }
    if (formData === null) {
      postService(res);
    } else {
      EditServices({ body: res, id: formData?.id });
    }
  };

  return (
    <Box width="450px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <FormLabel>Select Consultation Mode*</FormLabel>
          <Select
            fullWidth
            required
            variant="standard"
            value={selectedMode}
            onChange={handleChangeMode}
          >
            {consultationModes?.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <ImageUpload
          picDetails={picDetails}
          setPicDetails={setPicDetails}
          editImage={editImage}
          setEditImage={setEditImage}
          title={"photo"}
        />

        <TextField
          label="Title"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("title", {
            required: "Title is required",
          })}
          error={Boolean(errors.title)}
          helperText={!errors.title?.message}
        />

        <TextField
          label="Price"
          sx={{ mt: 1.5 }}
          fullWidth
          maxRows={3}
          variant="standard"
          {...register("price", {
            required: "Price is required",
          })}
          error={Boolean(errors.price)}
          helperText={!errors.price?.message}
        />

        <Button type="submit" sx={{ mt: 2 }} fullWidth variant="contained">
          Submit
        </Button>
      </form>
    </Box>
  );
};
