import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";

import { StylesProvider } from "@mui/styles";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import { themeCreator } from "./styles/themes/base";

const curThemeName = localStorage.getItem("appTheme") || "PureLightTheme";
const themeName = curThemeName;
const theme = themeCreator(themeName);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  </BrowserRouter>
);
