import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { deleteSymptom, getAllSymptoms } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import EmptyScreen from "../../../components/EmptyScreen";
import { DeleteButton, EditButton } from "../../../components/IconButtons";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import { SymptomForm } from "./SymptomsForm";

export const Symptoms = () => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  // const [symptomName, setSymptomName] = useState<any>("");
  // const [symptomNameArabic, setSymptomNameArabic] = useState("");
  // const [symptomId, setSymptomId] = useState();
  // const [edit, setEdit] = useState(false);

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const { data, isLoading } = useQuery(
    [
      "symptoms",
      {
        search: search,
        limit: limit,
        offset: limit * page,
      },
    ],
    getAllSymptoms
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const { mutate: addSymptom } = useMutation(createSymptoms, {
  //   onSuccess: (res: any) => {
  //     queryClient.invalidateQueries("symptoms");
  //     toast.success(res?.data?.message);
  //     toggleDrawer(false);
  //     setSymptomName("");
  //     setSymptomNameArabic("");
  //   },
  //   onError: (res: any) => {
  //     toast.error(res?.response?.data?.message);
  //   },
  // });

  // const { mutate: EditSymptom } = useMutation(updateSymptoms, {
  //   onSuccess: (res: any) => {
  //     queryClient.invalidateQueries("symptoms");
  //     toast.success(res?.data?.message);
  //     toggleDrawer(false);
  //     setSymptomName("");
  //     setSymptomNameArabic("");
  //   },
  //   onError: (res: any) => {
  //     toast.error(res?.response?.data?.message);
  //   },
  // });

  const { mutate: deleteSymptomMutate } = useMutation(deleteSymptom, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("symptoms");
      toast.success(res?.data?.message);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  // const handleSubmit = () => {
  //   if (edit === true) {
  //     const res = { title: symptomName || symptomNameArabic };
  //     EditSymptom({ body: res, id: symptomId });
  //   } else {
  //     addSymptom({ title: symptomName || symptomNameArabic });
  //   }
  // };

  const handleEditClick = (data: any) => {
    // setSymptomName(data?.title);
    // setSymptomId(data?.id);
    // setEdit(true);

    setFormData(data);
    toggleDrawer(true);
  };
  const handleDeleteClick = (id: any) => {
    deleteSymptomMutate(id);
  };

  const handleAdd = () => {
    toggleDrawer(true);
    setFormData(null);
  };

  const Columns = [
    {
      title: "Symptom Name",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <Box display="flex" gap="20px">
          <EditButton onClick={() => handleEditClick(row)} />
          <DeleteButton onClick={() => handleDeleteClick(colData)} />
        </Box>
      ),
    },
  ];

  return (
    <Box my={2}>
      <Box mb={2} display="flex" gap={2} alignItems="center">
        <Typography variant="h3">Symptoms</Typography>
        <Button variant="contained" onClick={handleAdd}>
          + Add New Symptom
        </Button>
      </Box>
      <Box>
        <TextField
          label="Search by Symptom"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box>
        {data?.data?.count === 0 ? (
          <EmptyScreen type="symptoms" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Box mt={2}>
                <Table
                  key={"payouts"}
                  dataSource={data?.data?.symptoms}
                  rowKey={"id"}
                  columns={Columns}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data?.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography>Add Payout</Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          {/* <Box width="450px">
            <TextField
              fullWidth
              label="Symptom Name (English)"
              variant="standard"
              value={symptomName}
              onChange={(e) => setSymptomName(e.target.value)}
            />
            <TextField
              sx={{ mt: 2, mb: 3 }}
              fullWidth
              label="Symptom Name (Arabic)"
              variant="standard"
              value={symptomNameArabic}
              onChange={(e) => setSymptomNameArabic(e.target.value)}
            />
            <Button onClick={handleSubmit} variant="contained" fullWidth>
              Submit
            </Button>
          </Box> */}
          <SymptomForm
            formData={formData}
            toggleDrawer={(e: any) => toggleDrawer(e)}
          />
        </StyledDrawerContent>
      </Drawer>
    </Box>
  );
};
