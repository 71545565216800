import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Box,
  Button,
  Card,
  Drawer,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { getAllRoles } from "../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";

export const Roles = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [roleName, setRoleName] = useState("");
  // const [selectedPermissions, setSelectedPermissions] = useState<any>(
  //   formData?.symptoms ? formData?.symptoms : []
  // );

  const toggleDrawer = (newOpen: any) => {
    setOpenDrawer(newOpen);
  };

  const { data, isLoading } = useQuery("roles", getAllRoles);

  const RoleCard = ({ title, detail, onClickEdit, onClickDelete }: any) => {
    return (
      <Card sx={{ mt: 2, py: 4, px: 2.5, maxWidth: "700px" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <NameBox title={title} detail={detail} />
          <Box display="flex" gap={1}>
            <ModeEditOutlineOutlinedIcon
              color="primary"
              sx={{ cursor: "pointer" }}
              onClick={onClickEdit}
            />
            <DeleteForeverOutlinedIcon
              color="error"
              sx={{ cursor: "pointer" }}
              onClick={onClickDelete}
            />
          </Box>
        </Box>
      </Card>
    );
  };

  const item = [
    {
      id: 1,
      title: "Therapist",
    },
    {
      id: 2,
      title: "patients",
    },
    {
      id: 3,
      title: "Sessions",
    },
    {
      id: 4,
      title: "Payments",
    },
  ];

  return (
    <Box>
      <Box display="flex" alignItems="center" gap={2}>
        <Typography variant="h4">Roles and Permissions</Typography>
        <Button variant="contained" onClick={() => toggleDrawer(true)}>
          Generate New Role
        </Button>
      </Box>

      <Grid container spacing={2}>
        {item.map((item, index) => (
          <Grid item xs={4} key={index}>
            <RoleCard
              title={item.title}
              detail="Number of Permissions added : 3"
              onClickEdit={() => navigate("/:id/edit-role")}
            />
          </Grid>
        ))}
      </Grid>
      <Box>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => toggleDrawer(false)}
        >
          <StyledDrawerHeader>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Inter_semibold",
                color: "black",
              }}
            >
              {"Add Role"}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter_semibold",
                color: "black",
                opacity: "0.5",
                cursor: "pointer",
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <Box sx={{ width: "450px", textAlign: "center" }}>
              <TextField
                label="Role Name"
                fullWidth
                variant="standard"
                value={roleName}
                onChange={(e) => setRoleName(e.target.value)}
              />
              <Button
                sx={{ mt: 3, width: "300px" }}
                variant="contained"
                onClick={() => navigate("new-role", { state: { roleName } })}
              >
                Submit
              </Button>
            </Box>
          </StyledDrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};

const NameBox = ({ title, detail }: any) => {
  return (
    <Box>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {title}
      </Typography>
      <Typography variant="caption">{detail}</Typography>
    </Box>
  );
};
