import { Box, Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { createUser, updateUser } from "../../api/services/api";

export const UserForm = ({ toggleDrawer, formData, buttonText }: any) => {
  const queryClient = useQueryClient();

  const { mutate: addUser } = useMutation(createUser, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("admin-users");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const { mutate: EditUser } = useMutation(updateUser, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("admin-users");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const isEditMode = Boolean(formData);

  const passwordValidation = isEditMode
    ? {}
    : { required: "Password is required" };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      fullName: formData?.fullName,
      email: formData?.email,
      mobileNumber: formData?.mobileNumber,
      password: "",
    },
  });

  console.log(formData);
  const onSubmit = (data: any) => {
    const res = {
      ...data,
      fullName: data?.fullName,
      email: data?.email,
      mobileNumber: data?.mobileNumber,
      password: data?.password,
    };
    if (formData === null) {
      addUser(res);
    } else {
      EditUser({ body: res, id: formData?.id });
    }
  };

  return (
    <Box width="450px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Full Name"
          fullWidth
          variant="standard"
          {...register("fullName", {
            required: "Full Name is required",
          })}
          error={Boolean(errors.fullName)}
          helperText={!errors.fullName?.message}
        />
        <TextField
          label="Email"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("email", {
            required: "Email is required",
          })}
          error={Boolean(errors.email)}
          helperText={!errors.email?.message}
        />
        <TextField
          label="Mobile Number"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("mobileNumber", {
            required: "Mobile is required",
          })}
          error={Boolean(errors.mobileNumber)}
          helperText={!errors.mobileNumber?.message}
        />
        <TextField
          label="Password"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("password", {
            required: "Password is required",
          })}
          error={Boolean(errors.password)}
          helperText={!errors.password?.message}
        />

        <Button type="submit" sx={{ mt: 2 }} fullWidth variant="contained">
          {buttonText}
        </Button>
      </form>
    </Box>
  );
};
