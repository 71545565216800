import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  IconButton,
  Rating,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { getReviews } from "../../../../api/services/api";
import EmptyScreen from "../../../../components/EmptyScreen";
import Loader from "../../../../components/Loader";
import Table from "../../../../components/TableComponent";

function TherapistReviews() {
  const clinicianId = useParams();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);

  const { data, isLoading } = useQuery(
    [
      "reports",
      {
        clinicianId: clinicianId?.id,
        search: search,
      },
    ],
    getReviews,
    {
      enabled: Boolean(clinicianId?.id),
    }
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Session ID",
      dataIndex: "session.sessionId",
      key: "session.sessionId",
    },

    {
      title: "Patient Name",
      dataIndex: "patient?.user",
      key: "patient?.user",
      render: (row: any, colData: any) => {
        return <Typography>{colData?.patient?.user?.fullName}</Typography>;
      },
    },

    {
      title: "Review Stars",
      dataIndex: "rating",
      key: "rating",
      render: (row: any) => {
        return <Rating value={row} readOnly />;
      },
    },
    {
      title: "Review Context",
      dataIndex: "comment" || "NA",
      key: "comment" || "NA",
      render: (row: any) => (
        <>
          {row === null ? (
            <Typography>NA</Typography>
          ) : (
            <Typography>{row}</Typography>
          )}
        </>
      ),
    },
    {
      title: "Posted On",
      dataIndex: "patient",
      key: "patient",
      render: (row: any, colData: any) => {
        return (
          <Typography>
            {moment(row?.user?.createdAt).format("DD-MMM-YYYY")}
          </Typography>
        );
      },
    },
  ];

  return (
    <Box>
      <Box mt={2} display="flex" gap="20px">
        <TextField
          label="Search by Session Id / Name"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box mt={2}>
        {data?.data.count === 0 ? (
          <EmptyScreen type="Reviews" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Box>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.reviews}
                  rowKey={"id"}
                  columns={columns}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

export default TherapistReviews;
