import {
  Avatar,
  Box,
  Card,
  CardHeader,
  styled,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
// import WatchLaterIcon from "@mui/icons-material/WatchLater";

import { icons } from "../../assets";

// const SessionChartWrapper = styled(SessionChart)(
//   () => `
//     height: 70px;
//     width: 160px;
//     `
// );

function AnalyticsCard({
  title,
  AvatarColor,
  bg,
  ChartColor,
  number,
  icon,
}: any) {
  const { t }: { t: any } = useTranslation();

  const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
            background-color: ${bg};
            color: ${AvatarColor};
            width: ${theme.spacing(6)};
            height: ${theme.spacing(6)};
            margin-top: -${theme.spacing(1)};
      `
  );

  const appointments = {
    month: [1083, 649, 312, 1538, 1404, 630, 1714, 853, 1765, 1067, 696, 538],
  };

  const generic = {
    month: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  const data = [
    {
      name: "Jan",
      TotalP: 4000,
    },
    {
      name: "Feb",
      TotalP: 3000,
    },
    {
      name: "March",
      TotalP: 4000,
    },
    {
      name: "April",
      TotalP: 1500,
    },
    {
      name: "May",
      TotalP: 5000,
    },
    {
      name: "July",
      TotalP: 4000,
    },
    {
      name: "Aug",
      TotalP: 3000,
    },
    {
      name: "Sept",
      TotalP: 2500,
    },
    {
      name: "Oct",
      TotalP: 2000,
    },
  ];

  return (
    <Card
      sx={{
        px: 1,
        py: 2,

        height: "100%",
      }}
    >
      {/* <CardHeader
        sx={{
          textAlign: "right",
          pb: 0,
          pl: 1,
        }}
        avatar={<AvatarPrimary>{icon}</AvatarPrimary>}
        action={
          <>
            <Label color="success">
              <ArrowUpwardTwoToneIcon fontSize="small" />
              <b>+10%</b>
            </Label>
            <Typography
              align="right"
              variant="subtitle1"
              color="text.secondary"
            >
              {t("since last month")}
            </Typography>
          </>
        }
      /> */}

      <Typography
        sx={{
          pb: 3,
          pl: 1,
          fontSize: "16px",
          opacity: "0.85",
        }}
      >
        {title}
      </Typography>

      <Box
        height="45%"
        width="98%"
        sx={{
          pl: 1,
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          height="100%"
          width="98%"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h1">{number}</Typography>
          </Box>
          {/* <ResponsiveContainer width="60%" height="85%">
            <BarChart
              data={data}
              barGap={3}
              barSize={7}
              margin={{
                top: 0,
                right: 0,
                left: 14,
                bottom: 2,
              }}
            >
              <Bar dataKey="TotalP" fill={ChartColor} radius={10} />
            </BarChart>
          </ResponsiveContainer> */}
        </Box>
      </Box>
    </Card>
  );
}

export function AnalyticsCardTemp({
  title,
  AvatarName,
  bg,
  ChartColor,
  number,
}: any) {
  const { t }: { t: any } = useTranslation();

  const AvatarPrimary = styled(Avatar)(
    ({ theme }) => `
            background-color: ${bg};
            width: ${theme.spacing(6)};
            height: ${theme.spacing(6)};
            margin-top: -${theme.spacing(1)};
      `
  );

  const appointments = {
    month: [1083, 649, 312, 1538, 1404, 630, 1714, 853, 1765, 1067, 696, 538],
  };

  const generic = {
    month: {
      labels: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  };

  const data = [
    {
      name: "Jan",
      TotalP: 4000,
    },
    {
      name: "Feb",
      TotalP: 3000,
    },
    {
      name: "March",
      TotalP: 4000,
    },
    {
      name: "April",
      TotalP: 1500,
    },
    {
      name: "May",
      TotalP: 5000,
    },
    {
      name: "July",
      TotalP: 4000,
    },
    {
      name: "Aug",
      TotalP: 3000,
    },
    {
      name: "Sept",
      TotalP: 2500,
    },
    {
      name: "Oct",
      TotalP: 2000,
    },
  ];

  return (
    <Card sx={{ p: "10px 10px 0px 10px" }}>
      <CardHeader
        sx={{
          textAlign: "right",
          pb: 0,
          pl: 1,
        }}
        avatar={
          <AvatarPrimary>
            <img
              src={
                AvatarName == "user"
                  ? icons.user
                  : AvatarName == "clinicians"
                  ? icons.clinicians
                  : AvatarName == "patients"
                  ? icons.patientIcn
                  : icons.patientIcn
              }
              alt="user"
              width="50px"
              height="50px"
            />
          </AvatarPrimary>
        }
      />
      <Box
        height="45%"
        width="98%"
        sx={{
          pl: 1,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Typography
              sx={{
                pb: "20px",
                fontSize: "16px",
                opacity: "0.85",
              }}
            >
              {title}
            </Typography>
            <Typography variant="h1">{number}</Typography>
          </Box>
          <Box width="200px" height="155px">
            {/* <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                barGap={3}
                barSize={7}
                margin={{
                  top: 50,
                  right: 0,
                  left: 20,
                  bottom: 2,
                }}
              >
                <Bar dataKey="TotalP" fill={ChartColor} radius={10} />
              </BarChart>
            </ResponsiveContainer> */}
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default AnalyticsCard;
