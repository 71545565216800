//@ts-nocheck
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import _without from "lodash/without";
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { http } from "../../../api/http";
import { getAllServices } from "../../../api/services/api";
import ImageUpload from "../../../components/ImageUpload";

const TherapistsForm = ({ toggleDrawer, data }: any) => {
  const queryClient = useQueryClient();
  const [edit, setEdit] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState();
  const [picDetails, setPicDetails] = useState(null);
  const [dob, setDob] = useState(
    data?.dob ? moment(new Date(data?.dob)) : moment(new Date())
  );
  const [expireDate, setExpireDate] = useState(
    data?.licenseExpiration
      ? moment(new Date(data?.licenseExpiration))
      : moment(new Date())
  );
  const [editImage, setEditImage] = useState(data?.imageUrl);
  const [selecteServices, setSelecteServices] = useState<any>(
    data?.services ? data?.services : []
  );

  useEffect(() => {
    data && setEdit(true);
    data && setId(data?.id);
  }, []);
  console.log(dob, "setDob");

  console.log(moment(new Date()).format("YYYY"), "date");
  console.log(moment(dob).format("YYYY"), "dateentered");
  const [currentAge, setcurrentAge] = useState(
    moment().format("YYYY") - moment(dob).format("YYYY")
  );
  console.log(currentAge, "currentAge");
  const [selectedLanguages, setSelectedLanguages] = useState(
    data?.languagesKnown ? data.languagesKnown.split(", ") : []
  );
  const handleChange = (event) => {
    setSelectedLanguages(event.target.value);
  };

  const { data: serviceData } = useQuery("services", getAllServices);

  const languages = ["Arabic", "English"];

  const handleChangeSymptom = (e: any) => {
    setSelecteServices(e.target.value);
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setSelecteServices((current: any) => _without(current, value));
  };

  const handleDeleteLanguage = (languageToDelete) => {
    setSelectedLanguages((currentLanguages) =>
      currentLanguages.filter((language) => language !== languageToDelete)
    );
  };
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onSubmit",
    defaultValues: {
      fullName: data?.user?.fullName,
      designation: data?.designation,
      mobileNumber: data?.user?.mobileNumber,
      gender: data?.gender,
      dob: moment(new Date(data?.dob)),
      experience: data?.experience === 0 ? "" : data?.experience,
      location: data?.location,
      email: data?.user?.email,
      age: data?.age,
      commision: +data?.commision,
      languagesKnown: data?.languagesKnown,
      license: data?.license,
      licenseExpiration: moment(new Date(data?.licenseExpiration)),
      audioModePrice: data?.audioModePrice,
      videoModePrice: data?.videoModePrice,
    },
  });

  const onSubmit = async (data: any) => {
    if (edit) {
      const formData = {
        ...data,
        key: picDetails?.key,
        dob: moment(dob).format("YYYY-MM-DD"),
        licenseExpiration: moment(expireDate).format("YYYY-MM-DD"),
        experience: data.experience,
        commision: +data?.commision,
        age: currentAge,
        serviceIds: selecteServices?.map((item: any) => item?.id),
        languagesKnown: selectedLanguages.join(", "),
      };
      // if (!formData?.imageUrl) {
      //   return toast.error("Image should not be empty");
      // }

      http
        .patch(`/clinicians/profile/${id}`, formData)
        .then((res) => {
          toast.success("The clinician has been updated");
          queryClient.invalidateQueries("therapists");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    } else {
      const formData = {
        ...data,
        key: picDetails?.Key,
        dob: moment(dob).format("YYYY-MM-DD"),
        // ...(data?.experience && { experience: +data.experience }),
        experience: data.experience,
        licenseExpiration: moment(expireDate).format("YYYY-MM-DD"),
        commision: +data?.commision,
        age: currentAge,
        serviceIds: selecteServices?.map((item: any) => item?.id),
      };
      // if (!formData?.key) {
      //   return toast.error("Image should not be empty");
      // }
      http
        .post(`/users/clinician-signup`, formData)
        .then((res) => {
          toast.success("The therapist has been created");
          queryClient.invalidateQueries("therapists");
          toggleDrawer(false);
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
        });
    }
  };

  return (
    <div>
      {" "}
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ImageUpload
            picDetails={picDetails}
            setPicDetails={setPicDetails}
            editImage={editImage}
            setEditImage={setEditImage}
            title={"photo"}
          />
          <TextField
            type={"text"}
            {...register("fullName", {
              required: true,
              pattern: { value: /^[A-Za-z,\- .]+$/ },
            })}
            label={"Name"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.fullName?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.fullName?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <TextField
            type={"email"}
            disabled={edit ? true : false}
            {...register("email", {
              required: true,
              pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
            })}
            label={"Email Address"}
            required={true}
            sx={{ marginTop: "24px" }}
            variant={"standard"}
            fullWidth={true}
          />
          {errors?.email?.type == "required" && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.email?.type == "pattern" && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              Invalid Email Address
            </Typography>
          )}
          <TextField
            type={"password"}
            {...register("password", { required: edit ? false : true })}
            label={"Password"}
            required={edit ? false : true}
            sx={{ marginTop: "24px" }}
            variant={"standard"}
            fullWidth
            type={showPassword ? "text" : "password"}
            InputProps={{
              endAdornment: (
                <InputAdornment
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowPassword(!showPassword)}
                  position="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </InputAdornment>
              ),
            }}
          />
          {errors?.password && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          <TextField
            type={"text"}
            disabled={edit ? true : false}
            {...register("mobileNumber", {
              required: true,
              pattern: /^\d{10}$/,
            })}
            label={"Mobile Number"}
            required={true}
            sx={{ marginTop: "24px" }}
            fullWidth={true}
            variant={"standard"}
          />
          {errors?.mobileNumber?.type == "required" && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          {errors?.mobileNumber?.type == "pattern" && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              Invalid Mobile Number
            </Typography>
          )}
          <FormControl
            component="fieldset"
            sx={{ marginTop: "24px" }}
            fullWidth={true}
          >
            <FormLabel component="legend">Gender *</FormLabel>
            <Controller
              rules={{ required: true }}
              control={control}
              name="gender"
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="MALE"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="FEMALE"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="OTHERS"
                    control={<Radio />}
                    label="Others"
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          {errors?.gender && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          <FormControl
            component="fieldset"
            sx={{ marginTop: "24px" }}
            fullWidth={true}
          >
            {/* <Controller
              render={({ field }) => ( */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <DatePicker
                  label="Date of Birth"
                  value={dob}
                  {...register("dob", {
                    required: true,
                  })}
                  maxDate={moment(new Date())}
                  onChange={(newValue) => {
                    setDob(moment(new Date(newValue)).toISOString());
                    setcurrentAge(
                      moment().format("YYYY") - moment(dob).format("YYYY")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      variant={"standard"}
                      fullWidth={true}
                      required={true}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
            {/* )}
              name="dob"
              control={control}
            /> */}
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: "24px" }}>
            <TextField
              type={"number"}
              value={currentAge}
              defaultValue={currentAge}
              inputProps={{ min: 0 }}
              {...register("age", {
                // required: edit ? false : true,
              })}
              label={"Age "}
              required={true}
              variant={"standard"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Year</InputAdornment>
                ),
              }}
            />
          </FormControl>
          {errors?.age && (
            <Typography
              sx={{
                fontFamily: "Inter_medium",
                color: "red",
              }}
            >
              This field is required
            </Typography>
          )}
          <TextField
            type={"text"}
            {...register("designation", {
              required: true,
              pattern: { value: /^[A-Za-z,\- .]+$/ },
            })}
            label={"Designation"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.designation?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.designation?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel
              id="services"
              sx={{
                ml: -1.5,
              }}
            >
              Services *
            </InputLabel>
            <Select
              labelId="services"
              fullWidth
              multiple
              required
              variant="standard"
              value={selecteServices}
              onChange={handleChangeSymptom}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value: any) => (
                    <Chip
                      key={value?.id}
                      clickable
                      deleteIcon={
                        <CancelSharpIcon
                          onMouseDown={(event: any) => event.stopPropagation()}
                        />
                      }
                      label={value?.title}
                      onDelete={(e: any) => handleDelete(e, value)}
                    />
                  ))}
                </Box>
              )}
            >
              {serviceData?.data?.services?.map((item: any, index: number) => (
                <MenuItem key={index} value={item}>
                  {item?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ marginTop: "24px" }}>
            <InputLabel
              id="languages-label"
              sx={{
                ml: -1.5,
              }}
            >
              Languages Known
            </InputLabel>
            <Select
              labelId="languages-label"
              multiple
              value={selectedLanguages}
              variant="standard"
              onChange={(event) => setSelectedLanguages(event.target.value)}
              renderValue={() => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selectedLanguages.map((language) => (
                    <Chip
                      key={language}
                      clickable
                      deleteIcon={
                        <CancelSharpIcon
                          onMouseDown={(event: any) => event.stopPropagation()}
                        />
                      }
                      label={language}
                      onDelete={() => handleDeleteLanguage(language)}
                      sx={{ marginRight: 1, marginBottom: 1 }}
                    />
                  ))}
                </Box>
              )}
            >
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ marginTop: "24px" }}>
            <TextField
              type={"number"}
              inputProps={{ min: 0 }}
              {...register("experience", {
                valueAsNumber: true,
              })}
              label={"Experience "}
              variant={"standard"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Years</InputAdornment>
                ),
              }}
            />
          </FormControl>

          <TextField
            sx={{ mt: 2 }}
            fullWidth
            type={"number"}
            {...register("commision", {
              required: true,
            })}
            label={"Commission "}
            variant={"standard"}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
          />
          <TextField
            type={"text"}
            {...register("location", {
              required: true,
              pattern: { value: /^[A-Za-z,\- .]+$/ },
            })}
            label={"Location"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.location?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          {errors?.location?.type == "pattern" && (
            <Typography sx={{ color: "red" }}>Invalid Name</Typography>
          )}
          <TextField
            {...register("license", {
              required: true,
            })}
            label={"License Number"}
            required={true}
            fullWidth={true}
            variant={"standard"}
            sx={{ marginTop: "24px" }}
          />
          {errors?.license?.type == "required" && (
            <Typography sx={{ color: "red" }}>
              This field is required
            </Typography>
          )}
          <FormControl
            component="fieldset"
            sx={{ marginTop: "24px" }}
            fullWidth={true}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box>
                <DatePicker
                  label="License Expiration Date"
                  value={expireDate}
                  {...register("licenseExpiration", {
                    required: false,
                  })}
                  minDate={moment(new Date())}
                  onChange={(newValue) => {
                    setExpireDate(moment(new Date(newValue)).toISOString());
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={null}
                      variant={"standard"}
                      fullWidth={true}
                      required={true}
                    />
                  )}
                />
              </Box>
            </LocalizationProvider>
          </FormControl>
          <TextField
            label="Audio Consultation Mode Price"
            sx={{ mt: 1.5 }}
            fullWidth
            variant="standard"
            {...register("audioModePrice", {
              required: "Audio Price is required",
              valueAsNumber: true,
            })}
            error={Boolean(errors.audioModePrice)}
            helperText={!errors.audioModePrice?.message}
          />
          <TextField
            label="Video Consultation Mode Price"
            sx={{ mt: 1.5 }}
            fullWidth
            variant="standard"
            {...register("videoModePrice", {
              required: "Video Price is required",
              valueAsNumber: true,
            })}
            error={Boolean(errors.videoModePrice)}
            helperText={!errors.videoModePrice?.message}
          />
          <Button type="submit" variant="contained" sx={{ marginTop: "24px" }}>
            Submit
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default TherapistsForm;
