import { icons } from "../../../assets";

export const toggleMenuItems = [
  {
    title: "Reports",
    path: "/reports",
    icon: icons.Block,
  },
  {
    title: "Master Data",
    path: "/master-data",
    icon: icons.Block,
  },
  {
    title: "Source Data",
    path: "/source-data",
    icon: icons.Block,
  },
];
export const menuItems = [
  {
    title: "Sessions",
    path: "/sessions",
    icon: icons.Clock,
  },
  {
    title: "Clinician",
    path: "/therapists",
    icon: icons.Therapist,
  },
  {
    title: "Clients",
    path: "/patients",
    icon: icons.Patients,
  },
  {
    title: "Payouts",
    path: "/payouts",
    icon: icons.payoutInc,
  },
  {
    title: "Reports",
    path: "/reports",
    icon: icons.reportsInc,
  },
  {
    title: "Resources",
    path: "/resources",
    icon: icons.Resources,
  },
  {
    title: "Services",
    path: "/services",
    icon: icons.service,
  },
  // {
  //   title: "Consultation Modes",
  //   path: "/consultationModes",
  //   icon: icons.service,
  // },
  {
    title: "Symptoms",
    path: "/symptoms",
    icon: icons.symptom,
  },
  {
    title: "Users",
    icon: icons.usersIcn,
    path: "/users",
  },
];

export const headerTitle = [
  {
    title: "Dashboard",
    path: "/dashboard",
  },
  {
    title: "Incentive Programs",
    path: "/incentive-programs",
  },
  {
    title: "Payouts",
    path: "/payouts",
  },
  {
    title: "Employees",
    path: "/employees",
  },
  {
    title: "Disputes",
    path: "/disputes",
  },
  {
    title: "Reports",
    path: "/reports",
  },
  {
    title: "Master Data",
    path: "/master-data",
  },
  {
    title: "Source Data",
    path: "/source-data",
  },
];
