import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import textCapitalization from "../../../components/textCapitalization";

export const ReportForm = ({
  handleSubmit,
  type,
  setType,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
}: any) => {
  const typeData = [
    "TOTAL_PATIENTS_CASES",
    "TOTAL_THERAPISTS_CASES",
    "PATIENT_GENERAL",
    "THERAPIST_GENERAL",
    "THERAPIST",
    "PATIENT",
  ];

  return (
    <Box>
      <Box width="550px">
        <Autocomplete
          options={typeData}
          getOptionLabel={(option) =>
            textCapitalization(option.replace(/_/g, " "))
          }
          value={type}
          onChange={(event, newValue) => {
            setType(newValue || "");
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant="standard"
              label="Select Report Type"
              // InputProps={{
              //   endAdornment: (
              //     <div
              //       className="MuiAutocomplete-endAdornment css-1q60rmi-MuiAutocomplete-endAdornment"
              //       style={{
              //         right: "0 !important",
              //         top: "calc(50% - 100px) !important",
              //       }}
              //     >
              //       {params.InputProps.endAdornment}
              //     </div>
              //   ),
              // }}
            />
          )}
        />

        <FormControl
          component="fieldset"
          sx={{ marginTop: "24px" }}
          fullWidth={true}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box>
              <DatePicker
                label="From Date"
                inputFormat="DD/MM/YYYY"
                value={fromDate}
                onChange={(e: any) => {
                  setFromDate(moment(new Date(e)).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"standard"}
                    fullWidth={true}
                  />
                )}
              />
            </Box>
            <Box mt="24px">
              <DatePicker
                label="To Date"
                inputFormat="DD/MM/YYYY"
                value={toDate}
                onChange={(e: any) => {
                  setToDate(moment(new Date(e)).format("YYYY-MM-DD"));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant={"standard"}
                    fullWidth={true}
                  />
                )}
              />
            </Box>
          </LocalizationProvider>
        </FormControl>
      </Box>
      <Button
        fullWidth
        onClick={handleSubmit}
        variant="contained"
        sx={{ marginTop: "32px", type: "submit" }}
      >
        Submit
      </Button>
    </Box>
  );
};
