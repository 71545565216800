import { Box } from "@mui/material";

import { Outlet } from "react-router-dom";
import Appbar from "../adminLayout/Appbar";
import { SettingSideNav } from "./SettingSideNav";

const SettingsLayout = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <Appbar />
      <SettingSideNav />
      <Box ml={42} mt={10} flex={1} mr={3.5}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default SettingsLayout;
