import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";

import SearchIcon from "@mui/icons-material/Search";

import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getReports, getReportsById } from "../../../../api/services/api";
import EmptyScreen from "../../../../components/EmptyScreen";
import { ViewButton } from "../../../../components/IconButtons";
import Loader from "../../../../components/Loader";
import Table from "../../../../components/TableComponent";
import textCapitalization from "../../../../components/textCapitalization";

export const PatientReports = () => {
  const patientId = useParams();
  const theme = useTheme();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [sessionId, setSessionId] = useState("");

  const { data, isLoading } = useQuery(
    [
      "reports",
      {
        patientId: patientId?.id,
        limit: limit,
        offset: limit * page,
      },
    ],
    getReports
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { data: getReportData } = useQuery(
    ["reports-id", sessionId],
    getReportsById,
    {
      enabled: Boolean(sessionId),
    }
  );

  const handleView = (id: any) => {
    setSessionId(id);
    setOpenDialog(true);
  };

  const columns = [
    {
      title: "Session ID",
      dataIndex: "sessionId",
      key: "sessionId",
    },

    {
      title: "Booked Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (colData: any) => {
        return moment(colData).format("DD-MM-YYYY");
      },
    },

    {
      title: "Client Name",
      dataIndex: "patientProfile.fullName",
      key: "patientProfile.fullName",
    },

    {
      title: "Mode Of Consultation",
      dataIndex: "consultationMode",
      key: "consultationMode",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },

    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <Tooltip title="View Reports" arrow>
          <Icon sx={{ height: "28px", ml: 1.5 }}>
            <ViewButton onClick={() => handleView(colData)} />
          </Icon>
        </Tooltip>
      ),
    },
  ];

  return (
    <Box>
      <Typography variant="h3">Reports</Typography>
      <Box mt={2} display="flex" gap="20px">
        <TextField
          label="Search by Session Id"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box mt={2}>
        {data?.data.count === 0 ? (
          <EmptyScreen type="reports" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Box>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.sessions}
                  rowKey={"id"}
                  columns={columns}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ fontSize: "20px" }}>Reports</DialogTitle>
        <DialogContent sx={{ maxWidth: "550px" }} dividers>
          <Box width="100%" height="100%">
            {getReportData?.data?.data.length === 0 ? (
              <Typography variant="h5" sx={{ opacity: "0.7" }}>
                No Reports Found...{" "}
              </Typography>
            ) : (
              <>
                {getReportData?.data?.data?.map((report: any, index: any) => (
                  <Box>
                    <Box maxHeight="500px">
                      <List sx={{ width: "500px", px: 1 }} key={index}>
                        <ListItem
                          secondaryAction={
                            <Box>
                              <a
                                href={report?.fileUrl}
                                download
                                style={{ textDecoration: "none" }}
                              >
                                <Button variant="outlined">
                                  <DownloadIcon sx={{ pr: 1 }} />
                                  Download Report
                                </Button>
                              </a>
                            </Box>
                          }
                        >
                          <Box
                            sx={{
                              backgroundColor: theme.colors.primary.lighter,
                              p: 1.5,
                              borderRadius: "30px",
                              display: "flex",
                              justifyContent: "center",
                              mr: 1,
                            }}
                          >
                            <Icon sx={{ color: theme.colors.primary.main }}>
                              <DescriptionOutlinedIcon />
                            </Icon>
                          </Box>
                          <ListItemText>Report {report.id}</ListItemText>
                        </ListItem>
                      </List>
                    </Box>
                  </Box>
                ))}
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
