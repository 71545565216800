import React from "react";
import { Box, Grid, Typography } from "@mui/material";

function ColorTags({ color, tag }: any) {
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Box
          height="20px"
          width="20px"
          sx={{
            borderRadius: "5px",
            backgroundColor: color,
          }}
        />
      </Grid>
      <Grid item>
        <Typography>{tag}</Typography>
      </Grid>
    </Grid>
  );
}

export default ColorTags;
