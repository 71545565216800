import { Avatar, Box, styled, Typography } from "@mui/material";

export const AvatarPrimary = styled(Avatar)(
  ({ theme }) => `
          background-color: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
          width: ${theme.spacing(6)};
          height: ${theme.spacing(6)};
          margin-top: -${theme.spacing(1)};
    `
);

export const DotLegend = styled("span")(
  ({ theme }) => `
  border-radius: 22px;
  width: ${theme.spacing(1.5)};
  height: ${theme.spacing(1.5)};
  display: inline-block;
  margin-right: ${theme.spacing(1)};
  margin-top: -${theme.spacing(0.1)};
`
);

export const BoxItemWrapper = styled(Box)(
  ({ theme }) => `
  border-radius: ${theme.general.borderRadius};
  background: ${theme.colors.alpha.black[5]};
  position: relative;
  padding: ${theme.spacing(2)};
  width: 100%;
  
  &::before {
    content: '.';
    background:  ${theme.colors.primary.main};
    color: ${theme.colors.primary.main};
    border-radius: ${theme.general.borderRadius};
    position: absolute;
    text-align: center;
    width: 8px;
    left: 0;
    height: 100%;
    top: 0;
  }
`
);

export const TitleText = styled(Typography)({
  fontFamily: "Inter_medium",
  fontSize: "16px",
  opacity: "0.6",
  padding: "20px 20px 12px 20px",
});

export const ProfileBox = styled(Box)({
  display: "flex",
  alignItems: "center",
});
