import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Collapse } from "@mui/material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { PopperPlacementType } from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses, TooltipProps } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { icons } from "../../../assets";
import { menuItems } from "./menu";

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

function SideNav() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(true);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [placement, setPlacement] = useState<PopperPlacementType>();
  const [state, setState] = useState({
    title: "Reports",
    path: "/reports",
  });

  const handleMenuClick = () => {
    setOpen(!open);
  };

  return (
    <Box
      sx={{
        // boxShadow: "0px 6px 16px #0000001A",
        width: "270px",
        height: "100vh",
        overflow: "hidden",
        position: "fixed",
        backgroundColor: "#333333",
        color: "white",
        padding: "15px",
      }}
    >
      <Box mt={8}>
        <List>
          <ListItemButton
            selected={location.pathname.startsWith("/dashboard")}
            onClick={handleMenuClick}
            sx={{
              "&.Mui-selected": {
                background: "#41958A",
                borderRadius: "5px",
              },
              "&.Mui-selected:hover": {
                background: "#41958A",
                borderRadius: "5px",
              },
              ":hover": {
                background: "#41958A",
              },
              display: "flex",
              alignItems: "center",
              // height: "80px",
              padding: "8px",
              marginTop: "15px",
            }}
          >
            <ListItemIcon>
              <Box
                sx={{
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <img src={icons.Block} alt="" width={20} height={20} />
              </Box>
            </ListItemIcon>
            <ListItemText
              color="white"
              primary={
                <Typography
                  variant="h6"
                  color="#FFFFFF"
                  fontSize="14.5px"
                  textAlign="left"
                >
                  {"Dashboard"}
                </Typography>
              }
            />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link
                to={"/dashboard"}
                style={{ textDecoration: "none", color: "initial" }}
              >
                <ListItemButton
                  selected={location.pathname.startsWith("/dashboard")}
                  sx={{
                    "&.Mui-selected": {
                      background: "#41958A",
                      borderRadius: "5px",
                    },
                    "&.Mui-selected:hover": {
                      background: "#41958A",
                      borderRadius: "5px",
                    },
                    ":hover": {
                      background: "#41958A",
                    },
                    display: "flex",
                    alignItems: "center",
                    // height: "80px",
                    padding: "8px",
                    marginTop: "12px",
                    marginLeft: "30px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.Clock} alt="" width={20} height={20} />
                    </Box>{" "}
                  </ListItemIcon>
                  <ListItemText
                    color="white"
                    primary={
                      <Typography
                        variant="h6"
                        color="#FFFFFF"
                        fontSize="14.5px"
                        textAlign="left"
                      >
                        {"Analytics"}
                      </Typography>
                    }
                  />{" "}
                </ListItemButton>
              </Link>
              {/* <Link
                to={"/payments-dashboard"}
                style={{ textDecoration: "none", color: "initial" }}
              >
                <ListItemButton
                  selected={location.pathname.startsWith("/payments-dashboard")}
                  sx={{
                    "&.Mui-selected": {
                      background: "#41958A",
                      borderRadius: "5px",
                    },
                    "&.Mui-selected:hover": {
                      background: "#41958A",
                      borderRadius: "5px",
                    },
                    ":hover": {
                      background: "#41958A",
                    },
                    display: "flex",
                    alignItems: "center",
                    // height: "80px",
                    padding: "8px",
                    marginTop: "12px",
                    marginLeft: "30px",
                  }}
                >
                  <ListItemIcon>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      <img src={icons.Wallet} alt="" width={20} height={20} />
                    </Box>{" "}
                  </ListItemIcon>
                  <ListItemText
                    color="white"
                    primary={
                      <Typography
                        variant="h6"
                        color="#FFFFFF"
                        fontSize="14.5px"
                        textAlign="left"
                      >
                        {"Payments Dashboard"}
                      </Typography>
                    }
                  />{" "}
                </ListItemButton>
              </Link> */}
            </List>
          </Collapse>
          {menuItems.map((item, index) => (
            <Link
              to={item.path}
              key={index}
              style={{ textDecoration: "none", color: "initial" }}
            >
              <ListItemButton
                selected={location.pathname.startsWith(item.path)}
                sx={{
                  "&.Mui-selected": {
                    background: "#41958A",
                    borderRadius: "5px",
                  },
                  "&.Mui-selected:hover": {
                    background: "#41958A",
                    borderRadius: "5px",
                  },
                  ":hover": {
                    background: "#41958A",
                  },
                  display: "flex",
                  alignItems: "center",
                  // height: "80px",
                  padding: "8px",
                  marginTop: "12px",
                }}
              >
                <ListItemIcon>
                  <Box
                    sx={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <img src={item.icon} alt="" width={20} height={20} />
                  </Box>
                </ListItemIcon>
                <ListItemText
                  color="white"
                  primary={
                    <Typography
                      variant="h6"
                      color="#FFFFFF"
                      fontSize="14.5px"
                      textAlign="left"
                    >
                      {item?.title}
                    </Typography>
                  }
                />
              </ListItemButton>
            </Link>
          ))}
          {/* <SidenavToggleItem state={state} CustomTooltip={CustomTooltip} />
          <CustomTooltip
            title={
              <Typography variant="subtitle2" sx={{ color: "white" }}>
                more
              </Typography>
            }
            TransitionComponent={Zoom}
            arrow
            placement="right"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <IconButton onClick={handleClick("right")}>
                  <MoreHorizIcon sx={{ color: "white", fontSize: "30px" }} />
                </IconButton>
              </ClickAwayListener>
            </Box>
          </CustomTooltip> */}
        </List>
      </Box>
      {/* <Popper open={open} anchorEl={anchorEl} placement={placement} transition>
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ ml: 7 }} elevation={2}>
              <Box
                sx={{
                  backgroundColor: "#080A19",
                  color: "white",
                  borderRadius: "5px",
                }}
              >
                <List>
                  {toggleMenuItems?.map((item, index) =>
                    state.title === item.title ? null : (
                      <ListItem disablePadding key={index}>
                        <ListItemButton
                          onClick={() => (
                            setState({
                              ...item,
                            }),
                            navigate(item.path)
                          )}
                        >
                          <ListItemIcon>
                            <Box
                              sx={{
                                width: "100%",
                                textAlign: "center",
                              }}
                            >
                              <img
                                src={item.icon}
                                alt=""
                                width={30}
                                height={30}
                              />
                            </Box>
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography
                                variant="h6"
                                color="#FFFFFF"
                                fontSize="12px"
                              >
                                {item.title}
                              </Typography>
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    )
                  )}
                </List>
              </Box>
            </Paper>
          </Fade>
        )}
      </Popper> */}
    </Box>
  );
}
export default SideNav;
