import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getMedicalRecords,
  getSessionById,
  updateClinicianSession,
} from "../../../api/services/api";
import Loader from "../../../components/Loader";
import textCapitalization from "../../../components/textCapitalization";
import { ChangeClinician } from "./ChangeClinician";
import { SessionDetailCard } from "./SessionDetailCard";

export const SessionView = () => {
  const theme = useTheme();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { id } = useParams();
  const [activeStep, setActiveStep] = React.useState(-1);
  const [filterData, setFilterData] = useState(id);
  const [patientProfileId, setpatientProfileId] = useState("");
  console.log(patientProfileId, "Check One");
  const [openDialog, setOpenDialog] = useState(false);
  const [openClinicians, setOpenClinicians] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { data, isLoading } = useQuery(
    ["sessions", filterData],
    getSessionById
  );
  console.log(data, "Check Now ID");

  const { data: getMedicalRecordsData } = useQuery(
    [
      "medical-record",
      {
        patientProfileId: patientProfileId,
      },
    ],
    getMedicalRecords
    // {
    //   enabled: Boolean(patientProfileId),
    // }
  );
  console.log(getMedicalRecordsData, "Medical");

  const { mutate } = useMutation(updateClinicianSession, {
    onSuccess: (res) => {
      console.log(res);
      queryClient.invalidateQueries("sessions");
      toast.success("Clinician Updated");
    },
    onError: (res: any) => {
      console.log(res);
      toast.error(res?.response?.data?.message);
    },
  });

  const currentDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");

  const handleChangeClinician = (newClinicianID: any) => {
    const res = {
      sessionId: data?.data?.id,
      previousClinicianId: data?.data?.clinician?.id,
      newClinicianId: newClinicianID,
      currentDate: currentDate,
    };
    mutate({ id: data?.data?.sessionClinician?.id, body: res });
    setOpenClinicians(false);
  };

  const handleReports = (id: any) => {
    console.log(id, "This is ID");
    setpatientProfileId(id);
    setOpenDialog(true);
  };

  const openMenu = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClinicianClick = (event: React.MouseEvent<HTMLElement>) => {
    if (data?.data?.status !== "PENDING") {
      setAnchorEl(event.currentTarget);
    } else {
      toast.error("Clinician cannot be selected due to Pending status");
    }
  };

  const handleMenu = () => {
    setOpenClinicians(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    const sessionArray = data?.data?.sessionStatuses;
    if (sessionArray) {
      const lastObject = sessionArray[sessionArray?.length - 1];
      const index = sessionArray?.indexOf(lastObject);
      console.log(index);
      setActiveStep(index);
    }
  }, [data?.data?.sessionStatuses]);

  let mode =
    data?.data?.consultationMode.slice(0, 1).toUpperCase() +
    data?.data?.consultationMode.slice(1).toLowerCase();

  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/sessions")}
        />
        <Typography variant="h3">Session Details</Typography>
      </Box>

      <Grid my="10px" container spacing={2}>
        {/* <Grid item xs={3.5}>
          <Card sx={{ pt: "25px" }}>
            <Typography pb={2} px={2} variant="h4">
              Other Sessions
            </Typography>
            <Divider />
            <Box p={2} overflow="auto" maxHeight="700px" className="scrollBox">
              <PendingSessionCard
                filterData={id}
                setFilterData={setFilterData}
              />
            </Box>
          </Card>
        </Grid> */}
        <Grid item xs={7.5}>
          <Card sx={{ pt: "25px" }}>
            <Typography pb={2} px={2} variant="h4">
              Sessions Details
            </Typography>
            <Divider />
            {isLoading ? (
              <Loader />
            ) : (
              <SessionDetailCard
                key={id}
                sessionId={data?.data?.sessionId}
                speciality={data?.data?.service?.title}
                modeOfConsultation={mode}
                bookedDate={moment(data?.data?.createdAt).format("DD-MM-YYYY")}
                sessionDate={moment(data?.data?.date).format("DD")}
                sessionMonth={moment(data?.data?.date).format("MMM")}
                timeSlots={data?.data?.sessionTimeslots}
                symptoms={data?.data?.symptom || "N/A"}
                description={data?.data?.description || "N/A"}
                doctorImage={data?.data?.sessionClinician?.clinician?.imageUrl}
                doctorName={
                  data?.data?.sessionClinician?.clinician?.user?.fullName
                }
                doctorExperience={
                  data?.data?.sessionClinician?.clinician?.experience
                }
                doctorSpeciality={
                  data?.data?.sessionClinician?.clinician?.designation
                }
                newClinician={data?.data?.sessionClinician?.newClinician}
                patientImage={data?.data?.patient?.imageUrl}
                patientName={data?.data?.patient?.user?.fullName}
                patientNumber={data?.data?.patient?.user?.mobileNumber || "N/A"}
                patientEmail={data?.data?.patient?.user?.email || "N/A"}
                patientProfileImage={data?.data?.patientProfile?.imageUrl}
                PatientProfileName={data?.data?.patientProfile?.fullName}
                PatientProfileAge={data?.data?.patientProfile?.age}
                PatientProfileGender={data?.data?.patientProfile?.gender}
                PatientLandmark={data?.data?.patientAddress?.landmark}
                patientAddress1={
                  data?.data?.patientAddress?.addressLine1 || "N/A"
                }
                patientAddress2={
                  data?.data?.patientAddress?.addressLine2 || "N/A"
                }
                PatientCity={data?.data?.patientAddress?.city}
                consultationFee={data?.data?.sessionPayment?.totalAmount}
                handleReportClick={() =>
                  handleReports(data?.data?.patientProfile?.id)
                }
                clinicianClick={handleClinicianClick}
                clinicianData={data?.data}
              />
            )}
          </Card>
        </Grid>
        <Grid item xs={4.5}>
          <Card sx={{ pt: "25px" }}>
            <Typography pb={2} px={2} variant="h4">
              Timeline
            </Typography>
            <Divider />
            <Box
              overflow="auto"
              maxHeight="700px"
              sx={{
                "@media (max-width: 1300px)": {
                  maxHeight: "570px",
                },
              }}
              className="scrollBox"
              maxWidth="500px"
              px={3}
            >
              <Stepper
                sx={{ background: "#fff" }}
                activeStep={activeStep}
                orientation="vertical"
              >
                {data?.data?.sessionStatuses?.map(
                  (step: any, index: number) => (
                    <Step key={index} completed={true}>
                      <StepLabel>
                        <Card
                          sx={{
                            ml: 1,
                            px: 2,
                            py: 2,
                            boxShadow: "0px 3px 20px #0018B614",
                          }}
                        >
                          <Typography variant="h6">
                            {textCapitalization(step.status.replace(/_/g, " "))}
                          </Typography>
                          <Typography mt={1}>
                            Created On:{" "}
                            {moment(step.createdAt).format(
                              "DD-MM-YYYY, hh:mm A"
                            )}
                          </Typography>
                        </Card>
                      </StepLabel>
                    </Step>
                  )
                )}
              </Stepper>
            </Box>
          </Card>
        </Grid>
      </Grid>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle sx={{ fontSize: "20px" }}>Medical Records</DialogTitle>
        <DialogContent sx={{ maxWidth: "550px" }} dividers>
          <Box width="100%" height="100%">
            {getMedicalRecordsData?.data?.data?.length === 0 ? (
              <Typography variant="h5" sx={{ opacity: "0.7" }}>
                No Medical Record Found...{" "}
              </Typography>
            ) : (
              <>
                {getMedicalRecordsData?.data?.data?.map((reports: any) => (
                  <>
                    <Box>
                      <Box maxHeight="500px">
                        <List sx={{ width: "500px", px: 1 }}>
                          <ListItem
                            secondaryAction={
                              <Box>
                                <a
                                  href={reports?.fileUrl}
                                  download
                                  style={{ textDecoration: "none" }}
                                >
                                  <Button variant="outlined">
                                    <DownloadIcon sx={{ pr: 1 }} />
                                    Download Report
                                  </Button>
                                </a>
                              </Box>
                            }
                          >
                            <Box
                              sx={{
                                backgroundColor: theme.colors.primary.lighter,
                                p: 1.5,
                                borderRadius: "30px",
                                display: "flex",
                                justifyContent: "center",
                                mr: 1,
                              }}
                            >
                              <Icon sx={{ color: theme.colors.primary.main }}>
                                <DescriptionOutlinedIcon />
                              </Icon>
                            </Box>
                            <ListItemText>Report {reports.id}</ListItemText>
                          </ListItem>
                        </List>
                      </Box>
                    </Box>
                  </>
                ))}
              </>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <Menu
        open={openMenu}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
      >
        <MenuItem onClick={handleMenu} sx={{ width: "150px" }}>
          <Typography>Change Clinician</Typography>
        </MenuItem>
      </Menu>

      <ChangeClinician
        sessionData={data?.data}
        handleChangeClinician={(e: any) => handleChangeClinician(e)}
        openClinicians={openClinicians}
        setOpenClinicians={setOpenClinicians}
      />
    </Box>
  );
};
