import { Box, Switch, Typography } from "@mui/material";

export const SlotBox = ({ startAt, endAt, onChange, checked }: any) => {
  return (
    <Box
      sx={{ backgroundColor: "#0052CC08" }}
      maxWidth="350px"
      maxHeight="65px"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p="20px"
      border="1px solid rgba(22, 22, 22, 0.16)"
      borderRadius="5px"
    >
      <Typography
        sx={{
          color: checked ? "#rgba(18, 18, 18, 1)" : "rgba(18, 18, 18, 0.4)",
        }}
      >
        {startAt} - {endAt}
      </Typography>
      <Switch checked={checked} onChange={onChange} />
    </Box>
  );
};
