import { Box, Button, Card, Drawer, Typography } from "@mui/material";
import moment from "moment";

import { useState } from "react";
import { useQuery } from "react-query";
import { getAdminReports } from "../../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../../components/DrawerTemplate";
import EmptyScreen from "../../../components/EmptyScreen";
import Loader from "../../../components/Loader";
import Table from "../../../components/TableComponent";
import textCapitalization from "../../../components/textCapitalization";
import { ReportForm } from "./ReportForm";

export const Reports = () => {
  const [open, setOpen] = useState(false);
  const [fromDate, setFromDate] = useState<any>(null);
  const [toDate, setToDate] = useState<any>(null);
  const [type, setType] = useState("");
  const [getData, setGetData] = useState(false);
  const [noData, setNoData] = useState(true);
  const [excelArr, setExcelArr] = useState<any>();

  const date = new Date();
  const todayDate = moment(date).format("DD-MM-YYYY");

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const { data, isLoading } = useQuery(
    [
      "report",
      {
        type: type,
        startDate: fromDate,
        endDate: toDate,
      },
    ],
    getAdminReports,
    {
      enabled: getData,
      onSuccess: (res) => {
        setOpen(false);
        setExcelArr(res?.data?.buffer?.data);
      },
      onError: () => {
        setNoData(true);
      },
    }
  );

  const handleExcelImport = () => {
    const arr = new Uint8Array(excelArr);
    const blob = new Blob([arr], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const file = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = file;
    link.download = `${textCapitalization(type)}_Report_${todayDate}.xlsx`;
    link.click();
  };

  const handleSubmit = () => {
    if (type && toDate && fromDate !== null) {
      setGetData(true);
      setNoData(false);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  const space = (data: any) => {
    const index = data.indexOf("D");
    const padMiddle = data.slice(0, index) + " ".repeat(1) + data.slice(index);
    return padMiddle;
  };

  const totalCasesColumn = [
    {
      title: "S No.",
      dataIndex: "S_No",
      key: "S_No",
    },
    {
      title: "Symptoms",
      dataIndex: "Symptom",
      key: "Symptom",
    },
    {
      title: "Total Cases",
      dataIndex: "Total_Cases",
      key: "Total_Cases",
    },
  ];

  const totalTherapistCasesColumn = [
    {
      title: "S No.",
      dataIndex: "S_No",
      key: "S_No",
    },
    {
      title: "Symptoms",
      dataIndex: "Symptom",
      key: "Symptom",
    },
    {
      title: "Total Cases",
      dataIndex: "Total_Cases",
      key: "Total_Cases",
    },
  ];

  const patientGeneralColumn = [
    {
      title: "S No.",
      dataIndex: "S_No",
      key: "S_No",
    },
    {
      title: "Patient Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },
    {
      title: "Country",
      dataIndex: "Country",
      key: "Country",
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
    },
    {
      title: "Clinician Name",
      dataIndex: "Clinician",
      key: "Clinician",
    },
    {
      title: "No. of Sessions",
      dataIndex: "PatientSessions",
      key: "PatientSessions",
    },
    {
      title: "Mode of Consultation",
      dataIndex: "Mode",
      key: "Mode",
    },
  ];

  const therapistGeneralColumn = [
    {
      title: "S No.",
      dataIndex: "S_No",
      key: "S_No",
    },
    {
      title: "Therapist Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
    },
    {
      title: "No. of Sessions",
      dataIndex: "TherapistSessions",
      key: "TherapistSessions",
    },
  ];

  const therapistColumn = [
    {
      title: "Therapist Name",
      dataIndex: "Name",
      key: "Name",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },
    {
      title: "Location",
      dataIndex: "Location",
      key: "Location",
    },
    {
      title: "No. of Sessions",
      dataIndex: "TherapistSessions",
      key: "TherapistSessions",
    },
  ];

  console.log(data?.data?.data);
  const patientColumn = [
    {
      title: "Patient Name",
      dataIndex: "Name",
      key: "  Name",
    },
    {
      title: "Age",
      dataIndex: "Age",
      key: "Age",
      render: (colData: any) => {
        return <Typography>{colData || "N/A"}</Typography>;
      },
    },
    {
      title: "Gender",
      dataIndex: "Gender",
      key: "Gender",
      render: (colData: any) => {
        return textCapitalization(colData || "");
      },
    },
    {
      title: "Country",
      dataIndex: "Country",
      key: "Country",
    },
    {
      title: "City",
      dataIndex: "City",
      key: "City",
    },
  ];

  return (
    <>
      <Box>
        <Box py={1.5} display="flex" gap={2} alignItems="center">
          <Typography variant="h3">Reports</Typography>
          <Button variant="contained" onClick={() => toggleDrawer(true)}>
            Generate New Report
          </Button>
        </Box>
        {noData === true ? (
          <Box mt={10}>
            <EmptyScreen type="Reports" />
          </Box>
        ) : (
          <>
            <Card>
              <Box display="flex" gap={2} alignItems="center" p="30px">
                <Box display="flex" flexGrow={1}>
                  <Box>
                    <Typography variant="caption">Report Type</Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontFamily: "Inter_regular" }}
                    >
                      {textCapitalization(type.replace(/_/g, " "))} Details
                      Report
                    </Typography>
                  </Box>
                </Box>
                <Box display="flex" flexGrow={1}>
                  <Box
                    borderLeft="1px solid"
                    mr={3}
                    sx={{ opacity: "0.1" }}
                    height="45px"
                  />
                  <NameBox title="From Date" detail={fromDate} />
                </Box>

                <Box display="flex" flexGrow={1}>
                  <Box
                    borderLeft="1px solid"
                    mr={3}
                    sx={{ opacity: "0.1" }}
                    height="45px"
                  />
                  <NameBox title="To Date" detail={toDate} />
                </Box>

                <Box display="flex" flexGrow={1}>
                  <Button onClick={handleExcelImport} variant="outlined">
                    Export Report
                  </Button>
                </Box>
              </Box>
            </Card>
            {type === "TOTAL_PATIENTS_CASES" && (
              <Box mt={2}>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={totalCasesColumn}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TablePagination
        count={data?.data.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={limit}
        onRowsPerPageChange={handleRowsPerPageChange}
      /> */}
                </Box>
              </Box>
            )}
            {type === "TOTAL_THERAPISTS_CASES" && (
              <Box mt={2}>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={totalTherapistCasesColumn}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TablePagination
        count={data?.data.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={limit}
        onRowsPerPageChange={handleRowsPerPageChange}
      /> */}
                </Box>
              </Box>
            )}
            {type === "PATIENT_GENERAL" && (
              <Box mt={2}>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={patientGeneralColumn}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TablePagination
        count={data?.data.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={limit}
        onRowsPerPageChange={handleRowsPerPageChange}
      /> */}
                </Box>
              </Box>
            )}
            {type === "THERAPIST_GENERAL" && (
              <Box mt={2}>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={therapistGeneralColumn}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TablePagination
        count={data?.data.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={limit}
        onRowsPerPageChange={handleRowsPerPageChange}
      /> */}
                </Box>
              </Box>
            )}
            {type === "THERAPIST" && (
              <Box mt={2}>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={therapistColumn}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TablePagination
        count={data?.data.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={limit}
        onRowsPerPageChange={handleRowsPerPageChange}
      /> */}
                </Box>
              </Box>
            )}
            {type === "PATIENT" && (
              <Box mt={2}>
                <Table
                  headAlign={"center"}
                  tableAlign={"center"}
                  key={"sessions"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={patientColumn}
                  loading={isLoading}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <TablePagination
        count={data?.data.count}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={limit}
        onRowsPerPageChange={handleRowsPerPageChange}
      /> */}
                </Box>
              </Box>
            )}
          </>
        )}

        <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
          <StyledDrawerHeader>
            <Typography>Add Payout</Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter_semibold",
                color: "black",
                opacity: "0.5",
                cursor: "pointer",
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <ReportForm
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              type={type}
              setType={setType}
              handleSubmit={handleSubmit}
            />
          </StyledDrawerContent>
        </Drawer>
      </Box>
    </>
  );
};

const NameBox = ({ title, detail }: any) => {
  return (
    <Box>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {detail}
      </Typography>
    </Box>
  );
};
