import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import {
  Box,
  Button,
  Card,
  Drawer,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { createRole, updateRole } from "../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";
import { PermissionCard } from "../../components/PermissionCard";

export const EditRoles = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [newRoleName, setNewRoleName] = useState("");
  const [openDrawer, setOpenDrawer] = useState(false);

  const location = useLocation();
  const { roleName } = location.state;
  console.log(roleName, "Ths is State Data");

  const toggleDrawer = (newOpen: any) => {
    setOpenDrawer(newOpen);
  };

  const { mutate: postRole } = useMutation(roleName ? createRole : updateRole, {
    onSuccess: (res: any) => {
      roleName
        ? toast.success("Role Created Successfully")
        : toast.success("Role Updated Successfully");

      queryClient.invalidateQueries("services");
    },
  });

  //   const handleRoleSubmit = () => {
  //     if (!roleName) {
  //       toast.error("Role name cannot be empty");
  //       return;
  //     }

  //     const mutationData = {
  //       role: roleName,
  //       // permissionIds: selectedPermissions?.map((item: any) => item?.id),
  //     };

  //     postRole(mutationData);
  //   };
  return (
    <Box>
      <Box display="flex" gap={1} alignItems="center">
        <ArrowBackIcon
          sx={{ cursor: "pointer" }}
          onClick={() => navigate("/settings/roles")}
        />
        <Typography variant="h3">New Roles</Typography>
      </Box>

      <Card sx={{ mt: 2, py: 4, px: 2.5, maxWidth: "700px" }}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <NameBox title="Role Name" detail={newRoleName || roleName} />
          <Box display="flex" gap={1}>
            <IconButton onClick={() => toggleDrawer(true)}>
              <ModeEditOutlineOutlinedIcon
                color="primary"
                sx={{ cursor: "pointer" }}
              />
            </IconButton>
          </Box>
        </Box>
      </Card>
      <Typography variant="h4" sx={{ mt: 3, mb: 2 }}>
        Permissions
      </Typography>
      <Box display="flex" flexDirection="column" gap={3}>
        <PermissionCard title="Therapist" />
        <PermissionCard title="patients" />
        <PermissionCard title="Sessions" />
        <PermissionCard title="Payments" />
        <PermissionCard title="Payouts" />
        <PermissionCard title="Resources" />
      </Box>

      <Box>
        <Drawer
          anchor="right"
          open={openDrawer}
          onClose={() => toggleDrawer(false)}
        >
          <StyledDrawerHeader>
            <Typography
              sx={{
                fontSize: "16px",
                fontFamily: "Inter_semibold",
                color: "black",
              }}
            >
              {"Add Role"}
            </Typography>
            <Typography
              sx={{
                fontSize: "14px",
                fontFamily: "Inter_semibold",
                color: "black",
                opacity: "0.5",
                cursor: "pointer",
              }}
              onClick={() => toggleDrawer(false)}
            >
              Cancel
            </Typography>
          </StyledDrawerHeader>
          <StyledDrawerContent>
            <Box sx={{ width: "450px", textAlign: "center" }}>
              <TextField
                label="Role Name"
                fullWidth
                variant="standard"
                value={newRoleName || roleName}
                onChange={(e) => setNewRoleName(e.target.value)}
              />
              <Button
                sx={{ mt: 3, width: "300px" }}
                variant="contained"
                onClick={() => {
                  toggleDrawer(false);
                }}
              >
                Submit
              </Button>
            </Box>
          </StyledDrawerContent>
        </Drawer>
      </Box>
    </Box>
  );
};

const NameBox = ({ title, detail }: any) => {
  return (
    <Box>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="h5" sx={{ fontFamily: "Inter_regular" }}>
        {detail}
      </Typography>
    </Box>
  );
};
