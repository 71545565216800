import CancelSharpIcon from "@mui/icons-material/CancelSharp";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import _without from "lodash/without";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import {
  createServices,
  getAllSymptoms,
  updateServices,
} from "../../../api/services/api";

export const ServiceForm = ({ toggleDrawer, formData }: any) => {
  const queryClient = useQueryClient();
  const [selectedSymptoms, setSelectedSymptoms] = useState<any>(
    formData?.symptoms ? formData?.symptoms : []
  );

  const { data: symptomsData, isLoading } = useQuery(
    "symptoms",
    getAllSymptoms
  );

  const handleChangeSymptom = (e: any) => {
    setSelectedSymptoms(e.target.value);
  };

  const handleDelete = (e: React.MouseEvent, value: string) => {
    e.preventDefault();
    setSelectedSymptoms((current: any) => _without(current, value));
  };

  const { mutate: postService } = useMutation(createServices, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("services");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const { mutate: EditServices } = useMutation(updateServices, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("services");
      toast.success(res?.data?.message);
      toggleDrawer(false);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: formData?.title,
      description: formData?.description,
      arabicTitle: formData?.arabicTitle,
      arabicDescription: formData?.arabicDescription,
      // audioModePrice: formData?.audioModePrice,
      // videoModePrice: formData?.videoModePrice,
    },
  });

  const onSubmit = (data: any) => {
    const res = {
      ...data,
      symptomIds: selectedSymptoms?.map((item: any) => item?.id),
    };
    if (formData === null) {
      postService(res);
    } else {
      EditServices({ body: res, id: formData?.id });
    }
  };

  console.log(onSubmit, "Check 1");

  return (
    <Box width="450px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl fullWidth sx={{ mb: 1.5 }}>
          <FormLabel>Select Symptoms*</FormLabel>
          <Select
            fullWidth
            multiple
            required
            variant="standard"
            value={selectedSymptoms}
            onChange={handleChangeSymptom}
            renderValue={(selected) => (
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                {selected.map((value: any) => (
                  <Chip
                    key={value?.id}
                    clickable
                    deleteIcon={
                      <CancelSharpIcon
                        onMouseDown={(event: any) => event.stopPropagation()}
                      />
                    }
                    label={value?.title}
                    onDelete={(e: any) => handleDelete(e, value)}
                  />
                ))}
              </Box>
            )}
          >
            {symptomsData?.data?.symptoms?.map((item: any, index: number) => (
              <MenuItem key={index} value={item}>
                {item?.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          label="Service Name(English)"
          fullWidth
          variant="standard"
          {...register("title", {
            required: "Service Name is required",
          })}
          error={Boolean(errors.title)}
          helperText={!errors.title?.message}
        />
        <TextField
          label="Service Name (Arabic)"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("arabicTitle")}
          error={Boolean(errors.title)}
          helperText={!errors.title?.message}
        />

        <TextField
          label="Description (English)"
          sx={{ mt: 1.5 }}
          fullWidth
          multiline
          maxRows={3}
          variant="standard"
          {...register("description", {
            required: "description is required",
          })}
          error={Boolean(errors.description)}
          helperText={!errors.description?.message}
        />
        <TextField
          sx={{ my: 1.5 }}
          label="Description (Arabic)"
          fullWidth
          multiline
          maxRows={3}
          variant="standard"
          {...register("arabicDescription")}
          error={Boolean(errors.description)}
          helperText={!errors.description?.message}
        />
        {/* <TextField
          label="Audio Consultation Mode Price"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("audioModePrice", {
            required: "Audio Price is required",
            valueAsNumber: true,
          })}
          error={Boolean(errors.audioModePrice)}
          helperText={!errors.audioModePrice?.message}
        />
        <TextField
          label="Video Consultation Mode Price"
          sx={{ mt: 1.5 }}
          fullWidth
          variant="standard"
          {...register("videoModePrice", {
            required: "Video Price is required",
            valueAsNumber: true,
          })}
          error={Boolean(errors.videoModePrice)}
          helperText={!errors.videoModePrice?.message}
        /> */}
        <Button type="submit" sx={{ mt: 2 }} fullWidth variant="contained">
          Submit
        </Button>
      </form>
    </Box>
  );
};
