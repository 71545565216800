import MoreVertIcon from "@mui/icons-material/MoreVert";
import PlaceOutlinedIcon from "@mui/icons-material/PlaceOutlined";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { icons } from "../../../assets";

interface SessionProps {
  sessionId: string;
  speciality: string;
  modeOfConsultation: string;
  bookedDate: any;
  sessionDate: any;
  sessionMonth: any;
  timeSlots: any;
  description: string;
  doctorImage: string;
  doctorName: string;
  doctorSpeciality: string;
  doctorExperience: string;
  patientImage: string;
  patientName: string;
  patientNumber: number;
  patientEmail: string;
  patientProfileImage: string;
  PatientProfileName: string;
  PatientProfileAge: number;
  PatientProfileGender: string;
  PatientLandmark: string;
  patientAddress1: string;
  patientAddress2: string;
  PatientCity: string;
  consultationFee: number;
  handleReportClick: any;
  clinicianClick: any;
  newClinician: any;
  clinicianData?: any;
  symptoms?: any;
}

export const SessionDetailCard = ({
  sessionId,
  speciality,
  modeOfConsultation,
  bookedDate,
  sessionDate,
  sessionMonth,
  timeSlots,
  symptoms,
  description,
  patientImage,
  patientName,
  patientNumber,
  patientEmail,
  patientProfileImage,
  PatientProfileName,
  PatientProfileAge,
  PatientProfileGender,
  PatientLandmark,
  patientAddress1,
  patientAddress2,
  PatientCity,
  consultationFee,
  doctorImage,
  doctorName,
  doctorSpeciality,
  doctorExperience,
  handleReportClick,
  clinicianClick,
  newClinician,
  clinicianData,
}: SessionProps) => {
  return (
    <Box
      p={2}
      overflow="auto"
      maxHeight="700px"
      sx={{
        "@media (max-width: 1300px)": {
          maxHeight: "570px",
        },
      }}
      className="scrollBox"
    >
      <Box p={2} border={"1px solid rgba(34, 34, 34, 0.08)"} borderRadius="5px">
        <Grid container alignItems="center" spacing={1.5}>
          <Grid item xs={6}>
            <TitleName title="Session Id" detail={sessionId} />
          </Grid>
          <Grid item xs={6}>
            <TitleName title="Service" detail={speciality} />
          </Grid>
          <Grid item xs={6}>
            <TitleName
              title="Mode of consultation"
              detail={modeOfConsultation}
            />
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" gap={1}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent={"center"}
                p={1}
                px={2.5}
                maxWidth="30px"
                alignItems="center"
                sx={{ backgroundColor: "#F0F0F0", borderRadius: "10px" }}
              >
                <Typography>{sessionDate}</Typography>
                <Typography>{sessionMonth}</Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <Typography variant="caption" sx={{ fontSize: "12px" }}>
                  Time Slot
                </Typography>
                <Typography>
                  {timeSlots?.map((item: any, index: number) => (
                    <Typography key={index}>
                      {item?.timeslot?.startAt}-{item?.timeslot?.endAt}
                    </Typography>
                  ))}
                </Typography>
              </Box>
            </Box>
          </Grid>
          {/* <Grid item xs={12}>
            <Box display="flex" flexDirection="column" justifyContent="center">
              <Typography variant="caption" sx={{ fontSize: "12px" }}>
                Symptoms
              </Typography>
              <Box display="flex" flexWrap="wrap" justifyContent="flex-end">
                {symptoms?.map((item: any, index: number) => (
                  <Box key={index} width="calc(50% - 0px)" mb={1}>
                    <Typography>{item?.title}</Typography>
                  </Box>
                ))}
              </Box>
            </Box>
          </Grid> */}
          <Grid item xs={12}>
            <TitleName title="Symptoms" detail={symptoms} />
          </Grid>
          <Grid item xs={12}>
            <TitleName title="Description" detail={description} />
          </Grid>
        </Grid>
      </Box>
      <Box
        mt={2}
        pt={1}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Box
          pb={1}
          px={2}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          {newClinician ? (
            <>
              <Typography variant="h5">Previous Clinician Details</Typography>
              {newClinician?.isNewClinicianAccepted !== true && (
                <IconButton onClick={clinicianClick}>
                  <MoreVertIcon />
                </IconButton>
              )}
            </>
          ) : (
            <>
              <Typography sx={{ py: 1 }} variant="h5">
                Clinician Details
              </Typography>
              {clinicianData?.status === "PAID" ||
                (clinicianData?.status === "APPROVED" && (
                  <IconButton onClick={clinicianClick}>
                    <MoreVertIcon />
                  </IconButton>
                ))}
            </>
          )}
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" p={2}>
          <Avatar
            variant="rounded"
            sx={{ height: "80px", width: "80px", fontSize: "40px" }}
            src={doctorImage}
            alt={doctorName}
          />
          <Box pl={2}>
            <Typography variant="h6">{doctorName}</Typography>
            <Typography variant="subtitle2" sx={{ color: "#1268AE" }}>
              {doctorSpeciality}
            </Typography>
            <Box
              sx={{
                backgroundColor: "#ECF3F9",
                px: "15px",
                py: "4px",
                mt: "5px",
                borderRadius: "11px",
              }}
            >
              <Typography variant="subtitle2">
                {doctorExperience} Year Exp.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {newClinician && (
        <Box
          mt={2}
          pt={1}
          border={"1px solid rgba(34, 34, 34, 0.08)"}
          borderRadius="5px"
        >
          <Box
            pb={1}
            px={2}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography sx={{ py: 1 }} variant="h5">
              New Clinician Details
            </Typography>
          </Box>
          <Divider />
          <Box display="flex" alignItems="center" p={2}>
            <Avatar
              variant="rounded"
              sx={{ height: "80px", width: "80px", fontSize: "40px" }}
              src={newClinician?.imageUrl}
              alt={newClinician?.user?.fullName}
            />
            <Box pl={2}>
              <Typography variant="h6">
                {newClinician?.user?.fullName}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "#1268AE" }}>
                {newClinician?.designation}
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#ECF3F9",
                  px: "15px",
                  py: "4px",
                  mt: "5px",
                  borderRadius: "11px",
                }}
              >
                <Typography variant="subtitle2">
                  {newClinician?.experience} Year Exp.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}

      {/* <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h5">
          User Details
        </Typography>
        <Divider />
        <Box display="flex" alignItems="center" p={2}>
          <Avatar
            variant="rounded"
            sx={{ height: "80px", width: "80px", fontSize: "40px" }}
            src={patientImage}
            alt={patientName}
          />
          <Box pl={2}>
            <Typography variant="h6">{patientName}</Typography>
            <Box display="flex" gap={1} alignItems="center">
              <img src={icons.mobileIcn} />
              <Typography>{patientNumber}</Typography>
            </Box>
            <Box display="flex" gap={1} alignItems="center">
              <img src={icons.emailIcn} />
              <Typography>{patientEmail}</Typography>
            </Box>
          </Box>
        </Box>
      </Box> */}
      <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h5">
          Client Details
        </Typography>
        <Divider />
        <Box
          display="flex"
          p={2}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box display="flex" alignItems="center">
            <Avatar
              variant="rounded"
              sx={{ height: "80px", width: "80px", fontSize: "40px" }}
              src={patientProfileImage}
              alt={PatientProfileName}
            />
            <Box pl={2}>
              <Typography variant="h6">{PatientProfileName}</Typography>
              <Typography variant="caption" sx={{ fontSize: "12px" }}>
                {PatientProfileAge} years
              </Typography>
              <Typography variant="subtitle2">
                {PatientProfileGender}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button variant="outlined" onClick={handleReportClick}>
              Medical Records
            </Button>
          </Box>
        </Box>
      </Box>
      {clinicianData?.consultationMode === "HOME" && (
        <Box
          mt={2}
          py={2}
          border={"1px solid rgba(34, 34, 34, 0.08)"}
          borderRadius="5px"
        >
          <Typography pb={2} px={2} variant="h5">
            Patient Location Details
          </Typography>
          <Divider />

          <Box display="flex" p={2} gap={1}>
            <PlaceOutlinedIcon />
            <Box>
              <Typography>{PatientCity}</Typography>
              <Typography>
                {patientAddress1}, {patientAddress2}, {PatientLandmark}{" "}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" px={2.4} gap={1.4} alignItems="center">
            <img src={icons.mobileIcn} />
            <Typography>{patientNumber}</Typography>
          </Box>
        </Box>
      )}
      <Box
        mt={2}
        pt={2}
        border={"1px solid rgba(34, 34, 34, 0.08)"}
        borderRadius="5px"
      >
        <Typography pb={2} px={2} variant="h5">
          Consultation Bill Details
        </Typography>
        <Divider />

        <Box display="flex" p={2} gap={1} justifyContent="space-between">
          <Typography>Consultation fee</Typography>
          <Typography>{consultationFee}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

const TitleName = ({ title, detail }: any) => {
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="caption" sx={{ fontSize: "12px" }}>
        {title}
      </Typography>
      <Typography>{detail}</Typography>
    </Box>
  );
};
