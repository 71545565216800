import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { deleteUser, getAllUsers } from "../../api/services/api";
import {
  StyledDrawerContent,
  StyledDrawerHeader,
} from "../../components/DrawerTemplate";
import EmptyScreen from "../../components/EmptyScreen";
import { DeleteButton, EditButton } from "../../components/IconButtons";
import Loader from "../../components/Loader";
import Table from "../../components/TableComponent";
import { UserForm } from "./UsersForm";

export const Users = () => {
  const queryClient = useQueryClient();
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState<any>(null);

  // const [edit, setEdit] = useState(false);
  // const [fullName, setFullName] = useState("");
  // const [email, setEmail] = useState("");
  // const [mobile, setMobile] = useState("");
  // const [password, setPassword] = useState("");
  // const [userId, setUserId] = useState();

  const { data, isLoading } = useQuery(
    [
      "admin-users",
      {
        search: search,
        limit: limit,
        offset: limit * page,
      },
    ],
    getAllUsers
  );

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const { mutate: deleteUserMutate } = useMutation(deleteUser, {
    onSuccess: (res: any) => {
      queryClient.invalidateQueries("admin-users");
      toast.success(res?.data?.message);
    },
    onError: (res: any) => {
      toast.error(res?.response?.data?.message);
    },
  });

  const handleEditClick = (data: any) => {
    // setFullName(data?.fullName);
    // setEmail(data?.email);
    // setMobile(data?.mobileNumber);
    // setPassword(data?.password);
    // setUserId(data?.id);
    // setEdit(true);
    setFormData(data);
    toggleDrawer(true);
  };

  const handleAdd = () => {
    toggleDrawer(true);
    setFormData(null);
  };

  const handleDeleteClick = (id: any) => {
    deleteUserMutate(id);
  };

  // const { mutate: addUser } = useMutation(createUser, {
  //   onSuccess: (res: any) => {
  //     queryClient.invalidateQueries("admin-users");
  //     toast.success(res?.data?.message);
  //     toggleDrawer(false);
  //     setFullName("");
  //     setEmail("");
  //     setMobile("");
  //     setPassword("");
  //   },
  //   onError: (res: any) => {
  //     toast.error(res?.response?.data?.message);
  //   },
  // });

  // const { mutate: EditUser } = useMutation(updateUser, {
  //   onSuccess: (res: any) => {
  //     queryClient.invalidateQueries("admin-users");
  //     toast.success(res?.data?.message);
  //     toggleDrawer(false);
  //     setFullName("");
  //     setEmail("");
  //     setMobile("");
  //     setPassword("");
  //   },
  //   onError: (res: any) => {
  //     toast.error(res?.response?.data?.message);
  //   },
  // });

  // const onSubmit = () => {
  //   if (edit === true) {
  //     const res = {
  //       fullName: fullName,
  //       email: email,
  //       mobileNumber: mobile,
  //       password: password,
  //     };
  //     EditUser({ body: res, id: userId });
  //   } else {
  //     addUser({
  //       fullName: fullName,
  //       email: email,
  //       mobileNumber: mobile,
  //       password: password,
  //     });
  //   }
  // };

  const toggleDrawer = (newOpen: any) => {
    setOpen(newOpen);
  };

  const Columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      key: "fullName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
    },
    {
      title: "User Type",
      dataIndex: "UserType",
      key: "UserType",
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "id",
      render: (colData: any, row: any) => (
        <Box display="flex" gap="20px">
          <EditButton onClick={() => handleEditClick(row)} />
          <DeleteButton onClick={() => handleDeleteClick(colData)} />
        </Box>
      ),
    },
  ];

  return (
    <Box my={2}>
      <Box mb={2} display="flex" gap={2} alignItems="center">
        <Typography variant="h3">Users</Typography>
        <Button variant="contained" onClick={handleAdd}>
          + Add User
        </Button>
      </Box>
      <Box>
        <TextField
          label="Search by user name"
          sx={{ width: "450px", padding: "0px" }}
          InputProps={{
            endAdornment: (
              <IconButton>
                <SearchIcon />
              </IconButton>
            ),
          }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Box>
        {data?.data?.count === 0 ? (
          <EmptyScreen type="users" />
        ) : (
          <>
            {isLoading ? (
              <Loader />
            ) : (
              <Box mt={2}>
                <Table
                  key={"users"}
                  dataSource={data?.data?.data}
                  rowKey={"id"}
                  columns={Columns}
                />
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <TablePagination
                    count={data?.data?.count}
                    page={page}
                    onPageChange={handlePageChange}
                    rowsPerPage={limit}
                    onRowsPerPageChange={handleRowsPerPageChange}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
      <Drawer anchor="right" open={open} onClose={() => toggleDrawer(false)}>
        <StyledDrawerHeader>
          <Typography>
            {formData ? "Edit User Details" : "Create User Details"}
          </Typography>{" "}
          <Typography
            sx={{
              fontSize: "14px",
              fontFamily: "Inter_semibold",
              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          {/* <Box width="450px">
            <TextField
              fullWidth
              label="Name"
              variant="standard"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Email"
              variant="standard"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              sx={{ mt: 2 }}
              fullWidth
              label="Mobile"
              variant="standard"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
            <TextField
              sx={{ mt: 2, mb: 3 }}
              fullWidth
              type="password"
              label="Password"
              variant="standard"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />

            <Button
              type="submit"
              onClick={onSubmit}
              variant="contained"
              fullWidth
            >
              Submit
            </Button>
          </Box> */}
          <UserForm
            formData={formData}
            toggleDrawer={(e: any) => toggleDrawer(e)}
            buttonText={formData ? "Edit" : "Submit"}
          />
        </StyledDrawerContent>
      </Drawer>
    </Box>
  );
};
