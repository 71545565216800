import { Box, Drawer, Menu, MenuItem, Typography } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../api/http";
import { icons } from "../assets";
import ArticlesForm from "../pages/admin/Resources/ArticlesForm";
import VideosForm from "../pages/admin/Resources/VideosForm";
import { StyledDrawerContent, StyledDrawerHeader } from "./DrawerTemplate";

const ResourcesCard = ({
  data,
  video,
  image,
  thumbnail,
  date,
  title,
  desc,
}: any) => {
  const createdAt = moment(new Date(date))?.format("DD-MM-YYYY");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [formData, setFormData] = useState([]);

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const [description, setDescription] = useState(desc);

  if (description?.length > 150) {
    setDescription(description.substring(0, 100) + "...");
  }
  const toggleDrawer = (newOpen: any) => {
    setOpenDrawer(newOpen);
  };

  const handleEditClick = () => {
    setFormData(data);
    toggleDrawer(true);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    http
      .delete(`/resources/${data?.id}`)
      .then(() => {
        toast.success("Item has been deleted");
        video
          ? queryClient.invalidateQueries("videos")
          : queryClient.invalidateQueries("articles");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message);
      });
  };
  return (
    <div>
      <Box sx={{ boxShadow: "0px 5px 15px #22222214;", borderRadius: "15px" }}>
        {video && (
          <>
            <Box
              sx={{
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "150px",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                position: "relative",
                cursor: "pointer",
              }}
              onClick={() => (window.location.href = video)}
            >
              <img
                src={icons?.Youtube}
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            </Box>
            <Box
              sx={{ padding: "14px 17px", height: "100px", cursor: "pointer" }}
              onClick={() => (window.location.href = video)}
            >
              <Typography variant="body1" sx={{ opacity: "1" }}>
                {title}
              </Typography>
              <Typography
                sx={{ fontSize: "10px", opacity: "0.7", marginTop: "10px" }}
              >
                {description}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "14px 17px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  opacity: "0.5",
                }}
              >
                {`Posted on ${createdAt}`}
              </Typography>
              <Box
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ background: "none", cursor: "pointer" }}
              >
                <img src={icons?.More} />
              </Box>
              <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  sx: {
                    overflow: "hidden",
                    mt: 1.5,
                    mb: 1.5,
                    width: "140px",
                    padding: "2px 12px",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => handleEditClick()}
                  sx={{ margin: "0px -12px 0px -12px" }}
                  focusRipple={false}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"12px"}
                  >
                    <img
                      style={{ width: "14px", height: "14px" }}
                      src={icons?.EditIcn}
                    />
                    <Typography sx={{ fontSize: "14px" }}>Edit</Typography>
                  </Box>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {}}
                  sx={{ margin: "0px -12px 0px -12px" }}
                  focusRipple={false}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"12px"}
                  >
                    <img
                      style={{ width: "14px", height: "14px" }}
                      src={icons?.HideIcon}
                    />
                    <Typography sx={{ fontSize: "14px" }}>Hide</Typography>
                  </Box>
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleDelete()}
                  sx={{ margin: "0px -12px 0px -12px" }}
                  focusRipple={false}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"12px"}
                  >
                    <img
                      style={{ width: "14px", height: "14px" }}
                      src={icons?.DeleteIcn}
                    />
                    <Typography sx={{ fontSize: "14px" }}>Delete</Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </>
        )}
        {image && (
          <>
            <Box
              sx={{
                backgroundImage: `url(${thumbnail})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "150px",
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                cursor: "pointer",
              }}
              onClick={() => (window.location.href = image)}
            />
            <Box
              sx={{ padding: "14px 17px", height: "100px", cursor: "pointer" }}
              onClick={() => (window.location.href = image)}
            >
              <Typography variant="body1" sx={{ opacity: "1" }}>
                {title}
              </Typography>
              <Typography
                sx={{ fontSize: "10px", opacity: "0.7", marginTop: "10px" }}
              >
                {description}
              </Typography>
            </Box>
            <Box
              sx={{
                padding: "14px 17px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "0px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "10px",
                  opacity: "0.5",
                }}
              >
                {`Posted on ${createdAt}`}
              </Typography>
              <Box
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                sx={{ background: "none", cursor: "pointer" }}
              >
                <img src={icons?.More} />
              </Box>
              <Menu
                // id="account-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  sx: {
                    overflow: "hidden",
                    mt: 1.5,
                    mb: 1.5,
                    width: "140px",
                    padding: "2px 12px",
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => handleEditClick()}
                  sx={{ margin: "0px -12px 0px -12px" }}
                  focusRipple={false}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"12px"}
                  >
                    <img
                      style={{ width: "14px", height: "14px" }}
                      src={icons?.EditIcn}
                    />
                    <Typography sx={{ fontSize: "14px" }}>Edit</Typography>
                  </Box>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {}}
                  sx={{ margin: "0px -12px 0px -12px" }}
                  focusRipple={false}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"12px"}
                  >
                    <img
                      style={{ width: "14px", height: "14px" }}
                      src={icons?.HideIcon}
                    />
                    <Typography sx={{ fontSize: "14px" }}>Hide</Typography>
                  </Box>
                </MenuItem> */}
                <MenuItem
                  onClick={() => handleDelete()}
                  sx={{ margin: "0px -12px 0px -12px" }}
                  focusRipple={false}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                    gap={"12px"}
                  >
                    <img
                      style={{ width: "14px", height: "14px" }}
                      src={icons?.DeleteIcn}
                    />
                    <Typography sx={{ fontSize: "14px" }}>Delete</Typography>
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
          </>
        )}
      </Box>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
      >
        <StyledDrawerHeader>
          <Typography
            sx={{
              fontSize: "14px",

              color: "black",
            }}
          >
            {"Edit Video Details"}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",

              color: "black",
              opacity: "0.5",
              cursor: "pointer",
            }}
            onClick={() => toggleDrawer(false)}
          >
            Cancel
          </Typography>
        </StyledDrawerHeader>
        <StyledDrawerContent>
          <Box sx={{ width: "550px" }}>
            {video ? (
              <VideosForm data={data} toggleDrawer={toggleDrawer} />
            ) : (
              <ArticlesForm data={data} toggleDrawer={toggleDrawer} />
            )}
          </Box>
        </StyledDrawerContent>
      </Drawer>
    </div>
  );
};

export default ResourcesCard;
