import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import * as XLSX from "xlsx";
import { getClinicianPayouts } from "../../../../../api/services/api";
import EmptyScreen from "../../../../../components/EmptyScreen";
import Loader from "../../../../../components/Loader";
import Table from "../../../../../components/TableComponent";

export const PayoutsList = ({ id }: any) => {
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState<number>(5);
  const [page, setPage] = useState<number>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [excelArr, setExcelArr] = useState<any>();
  const date = new Date();
  const todayDate = moment(date).format("DD-MM-YYYY");

  const open = Boolean(anchorEl);

  const { data, isLoading } = useQuery(
    [
      "payouts",
      {
        search: search,
        limit: limit,
        offset: limit * page,
        clinicianId: id,
        fromDate: startDate,
        toDate: endDate,
      },
    ],
    getClinicianPayouts,
    {
      enabled: Boolean(id),
    }
  );

  let i = 0;
  const excelData = data?.data?.payouts?.map((item: any) => ({
    "S.No": ++i,
    "Payment Reference ID": item?.paymentReferenceId,
    "Payment Date": moment(item?.paymentDate).format("DD-MM-YYYY"),
    Payment: `د.إ ${item?.amount}`,
    "Payment Mode": item?.paymentMode,
  }));

  useEffect(() => {
    async function generateFile(rows: any, fileName: string) {
      const worksheet = XLSX.utils?.json_to_sheet(rows);
      const workbook = XLSX.utils?.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
      return await XLSX?.write(workbook, { type: "buffer" });
    }
    data?.data?.payouts &&
      generateFile(excelData, "Report.xlsx").then((res) => {
        setExcelArr(res);
      });
  }, [data?.data?.payouts]);

  const handleExcelImport = () => {
    const arr = new Uint8Array(excelArr);
    const blob = new Blob([arr], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const file = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.href = file;
    link.download = `Report_${todayDate}.xlsx`;
    link.click();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: any) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const columns = [
    {
      title: "Payment Reference ID",
      dataIndex: "paymentReferenceId",
      key: "paymentReferenceId",
    },

    {
      title: "Payment Date",
      dataIndex: "paymentDate",
      key: "paymentDate",
      render: (colData: any) => moment(colData).format("DD-MMM-YYYY"),
    },
    {
      title: "Payment",
      dataIndex: "amount",
      key: "amount",
      render: (colData: any) => `د.إ${colData}`,
    },

    {
      title: "Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
    },
  ];

  return (
    <Box>
      <Box
        my={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" gap="20px">
          <TextField
            label="Search by Payment Reference ID"
            sx={{ width: "450px", padding: "0px" }}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            variant="outlined"
            startIcon={<FilterListOutlinedIcon />}
            onClick={handleClick}
          >
            <Typography>Filter</Typography>
          </Button>
          <Menu
            open={open}
            onClose={handleClose}
            anchorEl={anchorEl}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  value={startDate}
                  onChange={(newValue: any) => {
                    setStartDate(
                      moment(new Date(newValue)).format("YYYY-MM-DD")
                    );
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ width: "230px", mr: 1 }} {...params} />
                  )}
                />
                <DatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  value={endDate}
                  onChange={(newValue: any) => {
                    setEndDate(moment(new Date(newValue)).format("YYYY-MM-DD"));
                  }}
                  renderInput={(params) => (
                    <TextField sx={{ width: "230px" }} {...params} />
                  )}
                />
              </LocalizationProvider>
            </MenuItem>
          </Menu>
        </Box>
        <Button
          onClick={handleExcelImport}
          variant="contained"
          disabled={data?.data?.count === 0 ? true : false}
        >
          Export Payouts
        </Button>
      </Box>
      {data?.data?.count === 0 ? (
        <EmptyScreen type="Payouts" />
      ) : (
        <>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              <Table
                headAlign={"left"}
                tableAlign={"left"}
                key={"payouts"}
                dataSource={data?.data?.payouts}
                rowKey={"id"}
                columns={columns}
                loading={isLoading}
              />
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <TablePagination
                  count={data?.data.count}
                  page={page}
                  onPageChange={handlePageChange}
                  rowsPerPage={limit}
                  onRowsPerPageChange={handleRowsPerPageChange}
                />
              </Box>
            </>
          )}
        </>
      )}
    </Box>
  );
};
