import { http } from "../http";

export const getDashboardAnalytics = () => {
  return http.get(`/dashboard/admin/analytics`);
};

export const getTopFiveAnalytics = () => {
  return http.get(`/dashboard/admin/top-five`);
};

export const paymentAnalytics = () => {
  return http.get(`/dashboard/payments`);
};

export const getTopChargedClinicians = () => {
  return http.get(`/dashboard/payments/top-five`);
};

export const getPayoutAnalytics = () => {
  return http.get(`/payouts/admin/payment-analytics`);
};

export const getSessions = ({ queryKey }: any) => {
  return http.get("/sessions", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getSessionById = ({ queryKey }: any) => {
  return http.get(`/sessions/${queryKey[1]}`);
};

export function updateClinicianSession({ id, body }: any) {
  return http.patch(`/sessions/assign-new-clinician/${id}`, body);
}

export const getPatients = ({ queryKey }: any) => {
  return http.get("/patients", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getClinicians = ({ queryKey }: any) => {
  return http.get("/clinicians", {
    params: {
      ...queryKey[1],
    },
  });
};

export function getAvailableClinicians({ queryKey }: any) {
  return http.get("/sessions/available-clinicians", {
    params: {
      ...queryKey[1],
    },
  });
}

export const getClinicianProfile = () => {
  return http.get("/clinicians/profile");
};

export const getClinicianProfileById = ({ queryKey }: any) => {
  return http.get(`/clinicians/profile/${queryKey[1]}`);
};

export const getClinicianPayouts = ({ queryKey }: any) => {
  return http.get("/payouts", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getPatientById = ({ queryKey }: any) => {
  return http.get(`/patients/patient-profiles/${queryKey[1]}`);
};

export const getPayoutStatistics = ({ queryKey }: any) => {
  return http.get(`/payouts/clinicians/${queryKey[1]}`);
};

export const getPayoutAmount = ({ queryKey }: any) => {
  return http.get(`/payouts/clinicians/${queryKey[1]}/pending`);
};

export const getClinicianDashboardData = ({ queryKey }: any) => {
  return http.get(`/dashboard/clinicians/${queryKey[1]}`);
};

export const getClinicianPatients = ({ queryKey }: any) => {
  return http.get(`/clinicians/${queryKey[1]}/patients`, {
    params: {
      ...queryKey[2],
    },
  });
};

export const getReviews = ({ queryKey }: any) => {
  return http.get(`/reviews`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getReports = ({ queryKey }: any) => {
  return http.get(`/reports`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getMedicalRecords = ({ queryKey }: any) => {
  return http.get(`/medical-records`, {
    params: {
      ...queryKey[1],
    },
  });
};

export const getReportsById = ({ queryKey }: any) => {
  return http.get(`/reports/${queryKey[1]}`);
};

export const getClinicianTimeSlots = async ({ queryKey }: any) => {
  return await http.get(`clinician-time-slot/clinicians/${queryKey[1]}/slots`);
};

export const updateTimeSlots = (body: any) => {
  return http.patch("clinician-time-slot", body);
};

export const createPayouts = (body: any) => {
  return http.post("payouts", body);
};

export const getAllPayouts = ({ queryKey }: any) => {
  return http.get("payouts", {
    params: {
      ...queryKey[1],
    },
  });
};

export const getPayoutById = ({ queryKey }: any) => {
  return http.get(`/payouts/${queryKey[1]}`);
};

export const updatePayout = ({ data, id }: any) => {
  return http.patch(`payouts/${id}`, data);
};

export const deletePayout = (id: any) => {
  return http.delete(`payouts/${id}`);
};

export const getPatientDasboardData = ({ queryKey }: any) => {
  return http.get(`/dashboard/patients/${queryKey[1]}`);
};

export const payoutSats = () => {
  return http.get(`/payouts/statistics`);
};

export const getAdminReports = ({ queryKey }: any) => {
  return http.get(`/dashboard/admin/reports`, {
    params: {
      ...queryKey[1],
    },
  });
};

export function getAllResources({ queryKey }: any) {
  return http.get(`/resources/`, {
    params: {
      ...queryKey[1],
    },
  });
}

export const createServices = (body: any) => {
  return http.post(`/services/`, body);
};

export function getAllServices({ queryKey }: any) {
  return http.get(`/services/`, {
    params: {
      ...queryKey[1],
    },
  });
}

export const updateServices = ({ body, id }: any) => {
  return http.patch(`/services/${id}`, body);
};

export const createConsultationMode = (body: any) => {
  return http.post(`/mode-of-consultation/`, body);
};

export function getAllConsultationModes({ queryKey }: any) {
  return http.get(`/mode-of-consultation/`, {
    params: {
      ...queryKey[1],
    },
  });
}

export const updateConsultationMode = ({ body, id }: any) => {
  return http.patch(`/mode-of-consultation/${id}`, body);
};

export const deleteConsultationMode = (id: any) => {
  return http.delete(`/mode-of-consultation/${id}`);
};

export function getAllSymptoms({ queryKey }: any) {
  return http.get(`/symptoms/`, {
    params: {
      ...queryKey[1],
    },
  });
}

export const deleteServices = (id: any) => {
  return http.delete(`/services/${id}`);
};

export const createSymptoms = (body: any) => {
  return http.post(`/symptoms`, body);
};

export const updateSymptoms = ({ body, id }: any) => {
  return http.patch(`/symptoms/${id}`, body);
};

export const deleteSymptom = (id: any) => {
  return http.delete(`/symptoms/${id}`);
};

// roles
export const createRole = (body: any) => {
  return http.post("/roles", body);
};

export function getAllRoles({ queryKey }: any) {
  return http.get(`/roles`, {
    params: {
      ...queryKey[1],
    },
  });
}

export const getOneRole = ({ body, id }: any) => {
  return http.patch(`/roles/${id}`, body);
};

export const updateRole = ({ body, id }: any) => {
  return http.patch(`/roles/${id}`, body);
};

export const deleteRole = (id: any) => {
  return http.delete(`/roles/${id}`);
};

//users
export const createUser = (body: any) => {
  return http.post("/users/admin-users", body);
};

export function getAllUsers({ queryKey }: any) {
  return http.get(`/users/admin-users`, {
    params: {
      ...queryKey[1],
    },
  });
}

// export const getOneUser = ({ body, id }: any) => {
//   return http.patch(`/Users/${id}`, body);
// };

export const updateUser = ({ body, id }: any) => {
  return http.put(`/users/admin-users/${id}`, body);
};

export const deleteUser = (id: any) => {
  return http.delete(`/users/admin-users/${id}`);
};
