import {
  AppBar,
  Avatar,
  Box,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AlsanLogo, icons } from "../../assets";
import { StyledBadge } from "../styles";

const Appbar = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickLogout = () => {
    setAnchorEl(null);
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    localStorage.removeItem("userId");
    navigate("/login", { replace: true });
  };

  const handleClickLogo = () => {
    navigate("/dashboard");
  };

  // const hideSettingsIcon = location.pathname.includes("/settings");

  return (
    <>
      <AppBar color="inherit" position="fixed" sx={{ p: "10px 0 10px 25px" }}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ cursor: "pointer" }} onClick={handleClickLogo}>
            <img src={AlsanLogo} alt="logo" width="250px" height="60px" />
          </Box>
          <Box
            mr={2}
            display="flex"
            gap="10px"
            width="140px"
            justifyContent="flex-end"
          >
            {/* {!hideSettingsIcon && (
              <IconButton>
                <img
                  src={icons.SettingsIcon}
                  alt="settings"
                  onClick={() => navigate("/settings")}
                />
              </IconButton>
            )}
            <IconButton>
              <img src={icons.Notifications} alt="" />
            </IconButton> */}
            <Box sx={{ cursor: "pointer" }}>
              <div>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  variant="dot"
                >
                  <Avatar
                    onClick={handleMenu}
                    alt={""}
                    src={""}
                    sx={{ bgcolor: "#1A5C6E" }}
                  />
                </StyledBadge>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {/* <MenuItem
                    onClick={() => (navigate("/profile"), setAnchorEl(null))}
                  >
                    <Box padding="5px" display="flex" alignItems="center">
                      <img src={icons.Block} alt="" />
                      <Typography ml={1} variant="subtitle2">
                        My Profile
                      </Typography>
                    </Box>
                  </MenuItem> */}
                  <MenuItem onClick={handleClickLogout}>
                    <Box padding="5px" display="flex" alignItems="center">
                      <img src={icons.Block} alt="" />
                      <Typography ml={1} variant="subtitle2">
                        Logout
                      </Typography>
                    </Box>
                  </MenuItem>
                </Menu>
              </div>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Appbar;
