import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SummarizeIcon from "@mui/icons-material/Summarize";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  Icon,
  ListItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import {
  getClinicianDashboardData,
  getClinicianProfileById,
  getSessions,
} from "../../../../api/services/api";
import { icons } from "../../../../assets";
import AnalyticsCard from "../../../../components/AnalyticsCard/AnalyticsCard";
import Loader from "../../../../components/Loader";
import textCapitalization from "../../../../components/textCapitalization";
import { AvatarPrimary, BoxItemWrapper, ProfileBox, TitleText } from "./style";

function TotalAnalytics() {
  const clinicianId = useParams();
  const theme = useTheme();
  const [status, setStatus] = useState(["PENDING", "APPROVED", "PAID"]);

  const { data, isLoading } = useQuery(
    ["clinician-profile", clinicianId?.id],
    getClinicianProfileById,
    {
      enabled: Boolean(clinicianId?.id),
    }
  );

  const { data: dashboardData, isLoading: DashboadLoading } = useQuery(
    ["dashboard-data", clinicianId?.id],
    getClinicianDashboardData,
    {
      enabled: Boolean(clinicianId?.id),
    }
  );

  const { data: sessionData } = useQuery(
    [
      "sessions",
      {
        status: status,
        clinicianId: clinicianId?.id,
      },
    ],
    getSessions
  );

  let item = data?.data;

  let Gender =
    item?.gender.slice(0, 1).toUpperCase() +
    item?.gender.slice(1).toLowerCase();

  const COLORS = ["#FFA319", "#57CA22", "#0052CC"];

  const TypeColor = [
    "#FF3465",
    "#684ED5",
    "#57CA22",
    "#FFA319",
    "#D58E4E",
    "#032961",
  ];

  if (isLoading || DashboadLoading) {
    return <Loader />;
  }

  return (
    <>
      <Grid mb={3} container spacing={2}>
        <Grid item xs={9}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Total Analytics
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={4} md={6}>
              <AnalyticsCard
                title="Number of Sessions"
                icon={<WatchLaterIcon />}
                number={dashboardData?.data?.sessionDetails?.total || 0}
                AvatarColor={theme.colors.primary.main}
                bg={theme.colors.primary.lighter}
                ChartColor={theme.colors.primary.main}
              />
            </Grid>
            <Grid item lg={4} md={6}>
              <AnalyticsCard
                title="Number of Payments"
                icon={<SummarizeIcon />}
                number={dashboardData?.data?.sessionDetails?.paid || 0}
                AvatarColor={theme.colors.warning.main}
                bg={theme.colors.warning.lighter}
                ChartColor={theme.colors.warning.main}
              />
            </Grid>
            <Grid item lg={4} md={6}>
              <AnalyticsCard
                title="Total Clients"
                icon={<PersonAddIcon />}
                number={dashboardData?.data?.totalPatients || 0}
                AvatarColor={theme.colors.success.main}
                bg={theme.colors.success.lighter}
                ChartColor={theme.colors.success.main}
              />
            </Grid>
          </Grid>
          {/* <Typography variant="h4" sx={{ mb: 2, mt: 5 }}>
            Session Analytics
          </Typography>
          <Grid container spacing={2}>
            <Grid item lg={6}>
              <Card sx={{ width: "100%", height: "100%" }}>
                <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
                  <Typography>Sessions by Mode</Typography>
                </Box>
                <Box width="100%" height="85%" display="flex">
                  {dashboardData?.data?.sessionModes.every(
                    (item: any) => item.count === 0
                  ) ? (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="90%"
                      width="60%"
                    >
                      <Typography my={11} variant="h4">
                        No Data Available...
                      </Typography>
                    </Box>
                  ) : (
                    <>
                      <ResponsiveContainer width="65%" height="100%">
                        <PieChart>
                          <Pie
                            data={dashboardData?.data?.sessionModes}
                            dataKey="count"
                            nameKey="mode"
                            cx="40%"
                            cy="50%"
                            innerRadius={90}
                            outerRadius={110}
                            fill="color"
                          >
                            {dashboardData?.data?.sessionModes.map(
                              (entry: any, index: any) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                />
                              )
                            )}
                          </Pie>
                          <NewTip />
                        </PieChart>
                      </ResponsiveContainer>
                    </>
                  )}
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1.5}
                    justifyContent="center"
                  >
                    <ColorTags color="#57CA22" tag="Video" />
                    <ColorTags color="#FFA319" tag="Audio" />
                    <ColorTags color="#0052CC" tag="At Home" />
                  </Box>
                </Box>
              </Card>
            </Grid>
            <Grid item lg={6}>
              <Card sx={{ width: "100%", height: "100%" }}>
                <Box p={2} sx={{ backgroundColor: "rgba(0, 82, 204, 0.05)" }}>
                  <Typography>Sessions by Type</Typography>
                </Box>
                <Box width="100%" height="85%" display="flex">
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={dashboardData?.data?.sessionTypes}
                        dataKey="count"
                        nameKey="type"
                        cx="50%"
                        cy="50%"
                        outerRadius={110}
                        fill="#57CA22"
                      >
                        {dashboardData?.data?.sessionTypes.map(
                          (entry: any, index: any) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={TypeColor[index % TypeColor.length]}
                            />
                          )
                        )}
                      </Pie>
                      <NewTip />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </Card>
            </Grid>
          </Grid> */}

          <Card sx={{ width: "100%", marginTop: "20px", marginBottom: "80px" }}>
            <TitleText>Upcoming Sessions</TitleText>
            <Divider />
            {sessionData?.data?.count === 0 ? (
              <Typography variant="h5" sx={{ p: 2 }}>
                No Records Found...
              </Typography>
            ) : (
              <>
                {sessionData?.data?.sessions?.slice(0, 5)?.map((item: any) => (
                  <ListItem
                    component="div"
                    sx={{
                      pt: 2,
                    }}
                  >
                    <BoxItemWrapper
                      sx={{
                        "::before": {
                          background:
                            item?.status === "PENDING"
                              ? `${theme.colors.warning.main}`
                              : item?.status === "APPROVED"
                              ? `${theme.colors.success.main}`
                              : item?.status === "COMPLETED"
                              ? `${theme.colors.primary.main}`
                              : item?.status === "CANCELLED"
                              ? `${theme.colors.error.main}`
                              : "",
                          color:
                            item?.status === "PENDING"
                              ? `${theme.colors.warning.main}`
                              : item?.status === "APPROVED"
                              ? `${theme.colors.success.main}`
                              : item?.status === "COMPLETED"
                              ? `${theme.colors.primary.main}`
                              : item?.status === "CANCELLED"
                              ? `${theme.colors.error.main}`
                              : "",
                        },
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Tooltip
                          title={
                            item?.consultationMode === "HOME"
                              ? "Mode of Consultation is Home"
                              : item?.consultationMode === "AUDIO"
                              ? "Mode of Consultation is Audio"
                              : item?.consultationMode === "VIDEO"
                              ? "Mode of Consultation is Video"
                              : ""
                          }
                        >
                          <Icon>
                            {item?.consultationMode === "HOME" ? (
                              <img src={icons.homeIcon} alt="" width={25} />
                            ) : item?.consultationMode === "AUDIO" ? (
                              <img src={icons.audioIcon} alt="" width={25} />
                            ) : item?.consultationMode === "VIDEO" ? (
                              <img src={icons.videoIcon} alt="" width={23} />
                            ) : (
                              ""
                            )}
                          </Icon>
                          {/* <DotLegend
                            style={{
                              background:
                                item?.status === "PENDING"
                                  ? `${theme.colors.warning.main}`
                                  : item?.status === "APPROVED"
                                  ? `${theme.colors.success.main}`
                                  : item?.status === "PAID"
                                  ? `${theme.colors.primary.main}`
                                  : "",
                            }}
                          /> */}
                        </Tooltip>

                        <Typography
                          component="span"
                          sx={{
                            pl: 0.5,
                            fontWeight: "bold",
                          }}
                        >
                          {item?.patientProfile?.fullName}
                        </Typography>
                        {/* <Typography
                          sx={{
                            pl: 5,
                            opacity: 0.7,
                          }}
                        >
                          {item?.sessionId}
                        </Typography> */}

                        <Button
                          style={{
                            position: "absolute",
                            height: "35px",
                            maxWidth: "120px",
                            right: "30px",
                            top: "10px",
                            background:
                              item?.status === "PENDING"
                                ? `${theme.colors.warning.lighter}`
                                : item?.status === "APPROVED"
                                ? `${theme.colors.success.lighter}`
                                : item?.status === "PAID"
                                ? `${theme.colors.primary.lighter}`
                                : "",
                            color:
                              item?.status === "PENDING"
                                ? `${theme.colors.warning.main}`
                                : item?.status === "APPROVED"
                                ? `${theme.colors.success.main}`
                                : item?.status === "PAID"
                                ? `${theme.colors.primary.main}`
                                : "",
                          }}
                        >
                          {item?.status}
                        </Button>
                      </Box>
                      <Typography
                        sx={{
                          pl: 2.3,
                          pt: 1,
                          pb: 0.5,
                          opacity: 0.7,
                        }}
                      >
                        {item?.sessionId}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          pl: 2.3,
                          pb: 1,
                        }}
                      >
                        {moment(item?.date).format("DD-MMMM-YYYY")},&nbsp;
                        <b>
                          {moment(item?.startAt, "HH:mm").format("h:mm A")}-
                          {moment(item?.endAt, "HH:mm").format("h:mm A")}
                        </b>
                      </Typography>
                      <Box
                        sx={{
                          pl: 2.3,
                        }}
                      >
                        {item?.symptom && (
                          <Chip
                            variant="outlined"
                            sx={{
                              mr: 0.5,
                            }}
                            size="small"
                            label={item?.symptom}
                            color="primary"
                          />
                        )}
                        <Chip
                          variant="outlined"
                          sx={{
                            mr: 0.5,
                          }}
                          size="small"
                          label={textCapitalization(item?.consultationMode)}
                          color="primary"
                        />
                      </Box>
                    </BoxItemWrapper>
                  </ListItem>
                ))}
              </>
            )}
          </Card>
        </Grid>
        <Grid item xs={3}>
          <Card
            sx={{
              padding: "40px 30px 20px 30px",
            }}
          >
            <Box sx={{ textAlign: "center", margin: "auto", width: "50%" }}>
              <Avatar
                variant="rounded"
                sx={{ height: "142px", width: "142px" }}
                src={item?.imageUrl}
                alt={item?.user?.fullName}
              />
              <Typography variant="h3" m="15px 0 10px 0">
                {item?.user?.fullName}
              </Typography>
              <Typography variant="caption">{item?.location}</Typography>
            </Box>
            <Divider sx={{ mt: 1 }} />
            <Box py="20px" display="flex" flexDirection="column" gap={1.5}>
              <ProfileBox>
                <img src={icons.graduateIcn} alt="graduate" height="16px" />
                <Typography ml={1}>{item?.designation}</Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.mobileIcn} alt="mobile" height="16px" />
                <Typography ml={1}>{item?.user?.mobileNumber}</Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.emailIcn} alt="email" height="16px" />
                <Typography ml={1}>{item?.user?.email}</Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.experienceIcn} alt="experience" height="16px" />
                <Typography ml={1}>
                  {item?.experience} Year Experience
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.dobIcn} alt="dob" height="16px" />
                <Typography ml={1}>
                  {moment(item?.dob).format("DD-MM-YYYY")}
                </Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.genderIcn} alt="gender" height="16px" />
                <Typography ml={1}>{Gender}</Typography>
              </ProfileBox>
              <ProfileBox>
                <img src={icons.language} alt="gender" height="16px" />
                <Typography ml={1}>{item?.languagesKnown}</Typography>
              </ProfileBox>
              <ProfileBox gap={1}>
                <img src={icons.serviceIcon} alt="gender" height="16px" />
                {item?.services?.map((items: any, index: number) => (
                  <Box display="flex" key={index}>
                    <Typography>{items?.title}</Typography>
                    {item?.services?.length !== index + 1 && (
                      <Typography>,</Typography>
                    )}
                  </Box>
                ))}
              </ProfileBox>
            </Box>
          </Card>
          <Card sx={{ padding: "28px 20px 28px 20px", marginTop: "70px" }}>
            {/* <Typography>Overall Rating</Typography>
            <Typography mt={3} variant="h1">
              {Math.round(dashboardData?.data?.rating?.averageRating * 10) / 10}
            </Typography>
            <Rating
              readOnly
              sx={{ margin: "15px 0 20px 0" }}
              value={dashboardData?.data?.rating?.averageRating}
              precision={0.5}
            />
            <Divider /> */}
            <Typography m="0 0 24px 0">Total Reviews</Typography>
            <Typography variant="h1">
              {dashboardData?.data?.rating?.totalReviews || 0}
            </Typography>
          </Card>
          <Card sx={{ padding: "28px 20px 28px 20px", marginTop: "35px" }}>
            <AvatarPrimary>
              <WatchLaterIcon />
            </AvatarPrimary>
            <Typography mt={3}>Total School Reports Submitted</Typography>
            <Typography mt={2} variant="h1">
              {dashboardData?.data?.totalReports || 0}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default TotalAnalytics;
