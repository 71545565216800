import React from "react";
import { useQuery } from "react-query";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getPayoutAnalytics } from "../../../../api/services/api";

export const PayoutAnalytics = () => {
  const { data: payoutAnalyticsData } = useQuery(
    "payout-analtics",
    getPayoutAnalytics
  );
  const data = [
    {
      name: "Jan",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "Feb",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "March",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "April",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "May",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "July",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "Aug",
      TotalP: 3000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "Sept",
      TotalP: 4000,
      TherapistP: 2400,
      Alsan: 2400,
    },
    {
      name: "Oct",
      TotalP: 2000,
      TherapistP: 2400,
      Alsan: 2400,
    },
  ];
  return (
    <ResponsiveContainer width="99%" height="85%">
      <BarChart
        data={payoutAnalyticsData?.data}
        barGap={10}
        barSize={10}
        margin={{
          top: 30,
          right: 20,
          left: 20,
          bottom: 5,
        }}
      >
        <XAxis dataKey="month" />
        <YAxis />
        <Tooltip />
        <Bar dataKey="totalPayments" fill="#FFA319" radius={10} />
        <Bar dataKey="therapistPayments" fill="#0052CC" radius={10} />
        <Bar dataKey="commission" fill="#57CA22" radius={10} />
      </BarChart>
    </ResponsiveContainer>
  );
};
